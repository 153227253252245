const BaseUrl = '/service/';
const Title = 'UNEX TPS ONLINE';
const WsUrl = 'wss://tpsunex.mail2typeb.com/ws'
const BaseColor = '#820014';

module.exports = {
    BaseUrl,
    Title,
    WsUrl,
    BaseColor
}
