import moment from "moment";

export const convert = async (data) => {

    function convertHeader(data) {
        var sheetData = data.sheetData;
        var Detail = [];
        for (var i = 1; i < sheetData.length; i++) {
            var objekBaris = {};
            for (var j = 0; j < sheetData[0].length; j++) {
                objekBaris[sheetData[0][j].replace(/\s+/g, '_')] = sheetData[i][j];
            }

            const { MAWB, MAWB_DATE, HAWB, FLIGHT_NO, FLIGHT_DATE, HAWB_DATE, PIECES, WEIGHT } = objekBaris;

            Detail.push({
                mawb_number: MAWB,
                hawb_number: HAWB,
                mawb_date: moment(MAWB_DATE).format('YYYYMMDD') || "",
                hawb_date: moment(HAWB_DATE).format('YYYYMMDD') || "",
                flight_number: FLIGHT_NO,
                flight_date: moment(FLIGHT_DATE).format('DD-MMM-YYYY') || "",
                pieces: parseInt(PIECES),
                weight: parseInt(WEIGHT),
                isCreate: true
            });
        }
        return Detail;
    }

    var header = convertHeader(data[0]);
    return { header };
}