import { Fragment, useEffect } from 'react';
import AOS from 'aos';
import LoadingOverlay from 'react-loading-overlay';
import { useSelector } from 'react-redux';
import MainRouter from './content/MainRouter';

import './App.less';
import 'aos/dist/aos.css';

LoadingOverlay.propTypes = undefined;

function App() {
  const { loading } = useSelector(state => state.apps);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Durasi animasi (1 detik)
    });
  }, []);

  return (
    <Fragment>
      <LoadingOverlay
        active={loading}
        spinner
        text="Loading"
      >
        <MainRouter />
      </LoadingOverlay>
    </Fragment>
  );
}

export default App;
