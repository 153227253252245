import { Divider, Typography } from 'antd'
import React, { Fragment, useEffect } from 'react'
import CONFIG from '../../config/env';

const { Title } = CONFIG;

export default ({ title, children }) => {
    useEffect(() => {
        document.title = `${Title} (${title})`;
    }, [])

    return (
        <Fragment>
            <Typography.Title level={4}>{title}</Typography.Title>
            <Divider style={{ marginBlock: 16 }} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {children}
            </div>
        </Fragment>
    )
}