const levels = [
    {
        level: 0x1000,
        value: 'Super Admin'
    },
    {
        level: 0x01,
        value: 'Export',
    },
    {
        level: 0x02,
        value: 'Import'
    },
    {
        level: 0x04,
        value: 'Bea Cukai'
    },
    {
        level: 0x08,
        value: 'Checker',
    },
    // {
    //     level: 0x08,
    //     value: 'PDE'
    // },
];

export const roleToLevel = (roles) => {
    return levels
        .filter(({ value }) => {
            return Array.isArray(roles) && roles.indexOf(value) >= 0;
        })
        .reduce((acc, val) => {
            return acc + parseInt(val.level); // Add return statement here
        }, 0);
};

export const levelToRole = (level) => {
    return levels.reduce((acc, val) => {
        if ((val.level & level) > 0) {
            acc.push(val.value);
        }
        return acc;
    }, []).join(', ');
}