import React, { Fragment } from 'react';
import { Tabs } from 'antd';
import moment from 'moment';
import TablePage from '../../../../../../components/pages/TablePage';
import ReportOut from './report';
import PrintAwbExp from './printBarcode';
import { RiPrinterFill } from "react-icons/ri";

export default () => {
    const columns = [
        {
            selector: (row) => row?.awb_number,
            name: "MAWB Number",
            id: 'awb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => row?.pieces,
            name: "Pieces",
            id: 'weight'
        },
        {
            selector: (row) => row?.weight,
            name: "Weight",
            id: 'weight'
        },
        {
            selector: (row) => row?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "Flight Date",
            id: 'flight_date'
        },
        {
            selector: (row) => row?.origin.code,
            name: "Origin",
            id: 'origin'
        },
        {
            selector: (row) => row?.dest.code,
            name: "Dest",
            id: 'dest'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "Created Date",
            id: 'created_at'
        },
        {
            selector: (row) => {
                return (
                    <>
                        <PrintAwbExp
                            data={row}
                            title="Print Barcode"
                            icon={<RiPrinterFill />}
                            iconBtnProps={{
                                size: "small",
                            }}
                        />
                    </>
                )
            },
            name: "Print Awb",
            id: 'created_at'
        }
    ]
    return (
        <Fragment>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Airwaybill" key="1">
                    <TablePage
                        title={"Export Airwaybill"}
                        url="api/v1/export_awb"
                        actionPosition={'first'}
                        createPath="/tps/export/awb/form"
                        editPath={"/tps/export/awb/edit"}
                        columns={columns}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Report" key="2">
                    <ReportOut />
                </Tabs.TabPane>
            </Tabs>
        </Fragment>
    )
}