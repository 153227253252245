import React, { useState } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
import {
    RiFileSearchFill
} from 'react-icons/ri';
import Modals from './modal';

export default () => { // eslint-disable-line
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDataModal, setDataModal] = useState(null);
    const [refresh, setRefresh] = useState(moment().unix());

    const showModal = (row) => {
        setIsModalOpen(true);
        setDataModal(row)
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setDataModal(null)
    };

    const columns = [
        {
            selector: (row) => row?.mawb_number,
            name: "Master AWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => <span>{row?.origin?.code} - {row?.dest?.code}</span>,
            name: "Route",
            id: 'state'
        },
        {
            selector: (row) => row?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "FL Date",
            id: 'flight_date_str'
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Exportir Name",
            id: 'exportir_name',
            wrap: true,
        },
        {
            name: "Action",
            id: '_id',
            selector: (row) => {
                return (
                    <RiFileSearchFill size={20} style={{ color: 'green', cursor: 'pointer' }} onClick={() => showModal(row)} />
                )
            }
        },
    ]
    return (
        <>
            <Modals isModalOpen={isModalOpen} handleCancel={handleCancel} isDataModal={isDataModal} setRefresh={setRefresh} />
            <TablePage
                title={"Distribusi Verify"}
                url="api/v1/tps_distribusi/distribusi_verify"
                actionPosition={'right'}
                columns={columns}
                refresh={refresh}
            />
        </>
    )
}