import { Fragment, useState } from 'react';
import { useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import { convert } from './xlsToJson';
import * as XLSX from 'xlsx';
import { Button, Space, Table, message } from 'antd';
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../../../../../redux';
import { useHistory } from 'react-router-dom'
import { ArrowLeftOutlined, SaveOutlined, DownloadOutlined } from '@ant-design/icons';

export default () => { // eslint-disable-line
    const dispatch = useDispatch();
    const history = useHistory();

    const maxSize = 1024 * 1024;
    const [fileInfo, setFileInfo] = useState({
        info: [],
        isFilled: false,
        errMessage: "",
    });
    const [jsonResult, setJsonResult] = useState([]);

    const onDrop = useCallback(
        async (acceptedFiles, rejectedFiles) => {
            setFileInfo({
                info: [
                    ...(fileInfo.info || []), // Menyalin info yang sudah ada (jika ada)
                    ...acceptedFiles?.map((item) => ({
                        name: item.name,
                        size: item.size,
                    })),
                ],
                isFilled: true,
                errMessage: "",
            });

            if (rejectedFiles.length > 0) {
                setFileInfo({
                    ...fileInfo,
                    errMessage:
                        "Ukuran file yang diinputkan lebih dari 1MB.",
                });
                return;
            } else {

                const file = acceptedFiles[0];
                const reader = new FileReader();

                reader.onload = async (event) => {
                    const binaryString = event.target.result;
                    const workbook = XLSX.read(binaryString, { type: 'binary' });
                    const sheetNames = workbook.SheetNames;

                    const data = [];

                    sheetNames.forEach((sheetName) => {
                        const worksheet = workbook.Sheets[sheetName];
                        const sheetData = XLSX.utils.sheet_to_json(worksheet, {
                            header: 1,
                            raw: false, // Pastikan tanggal dianggap sebagai tanggal, bukan string
                        });
                        data.push({ sheetName, sheetData });
                    });
                    const result = await convert(data)
                    setJsonResult(result)
                };

                reader.readAsBinaryString(file);
            }
        },
        []
    );

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        maxSize: maxSize,
        accept: ".pdf",
        onDrop,
    });

    const onSave = () => {
        if (jsonResult == []) return true;
        const { header } = jsonResult;

        for (let index = 0; index < header.length; index++) {
            const { MAWB } = header[index];
            if (MAWB !== ""
            ) {
                const url = 'api/v1/export_btb/create_btb';
                dispatch(PostAPI({
                    url, data: header[index]
                })).then(resp => {
                    if (!!resp?.payload) {
                        message.success('upload awb berhasil');
                        setJsonResult([])
                    }
                })
            }
        }
        return false
    }

    const columnsHeader = [
        {
            title: 'mawb number',
            dataIndex: 'mawb_number',
            key: 'mawb_number',
        },
        {
            title: 'mawb date',
            dataIndex: 'mawb_date',
            key: 'mawb_date',
        },
        {
            title: 'hawb number',
            dataIndex: 'hawb_number',
            key: 'hawb_number',
        },
        {
            title: 'hawb date',
            dataIndex: 'hawb_date',
            key: 'hawb_date',
        },
        {
            title: 'flight number',
            dataIndex: 'flight_number',
            key: 'flight_number',
        },
        {
            title: 'flight date',
            dataIndex: 'flight_date',
            key: 'flight_date',
        },
        {
            title: 'pieces',
            dataIndex: 'pieces',
            key: 'pieces',
        },
        {
            title: 'weight',
            dataIndex: 'weight',
            key: 'weight',
        }
    ];

    return (
        <Fragment>
            <div
                style={{
                    border: `1px dashed #9C9C9C`,
                    textAlign: "center",
                    cursor: "pointer",
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p style={{ color: "#9C9C9C" }}>Drop the files here ...</p>
                ) : (
                    <>
                        <p style={{ color: fileInfo.errMessage ? "red" : "#9C9C9C" }}>
                            {fileInfo.errMessage || `Upload file disini (max 1MB)*`}
                        </p>
                    </>
                )}
            </div>
            <Table dataSource={jsonResult?.header} columns={columnsHeader} size='small' style={{ marginTop: 20 }} />
            <Space style={{ marginTop: 20 }}>
                <Button icon={<SaveOutlined />} size='large' onClick={() => onSave()} >Save</Button>
                <Button icon={<DownloadOutlined />} size='large'
                    onClick={() => {
                        window.open('/assets/template_export.xlsx', '__blank');
                    }}
                >Download Template</Button>
                <Button icon={<ArrowLeftOutlined />} type="primary" size='large' onClick={() => history.goBack()}>Back</Button>
            </Space>
        </Fragment>
    )
}