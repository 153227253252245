import React, { useMemo } from "react";
import { AppVersion, updateLog } from "../../../../config/appVersion";
import { Typography } from "antd";
import { useSelector } from "react-redux";

const About = () => {
  const { versionCache: beVersion } = useSelector(state => state.apps);

  const logs = useMemo(() => {
    if (updateLog.length < 5) return updateLog;
    const res = [];
    for (let iii = 0; iii < 5; iii++) {
      const log = updateLog[iii];
      res.push(log);
    }
    return res;
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", padding: 15 }}>
      <div
        style={{
          paddingBottom: 10,
          borderBottom: "1px solid #e0e0e0",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 25 }}>Version of App</Typography>
      </div>
      <div style={{ border: "1px solid black", padding: 15 }}>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Current Version
        </Typography>
        <Typography variant="body2">Frontend : {AppVersion} </Typography>
        <Typography variant="body2">Backend : {beVersion?.version} </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 25,
          justifyContent: "space-around",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
          <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
            Frontend Update History
          </Typography>
          {logs?.map((log, idx) => (
            <React.Fragment key={idx}>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                {" "}
                {log.version}{" "}
              </Typography>
              <ul>
                {log.update.map((upd, index) => (
                  <li key={`${idx}__${index}`}>{upd}</li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </div>
        <div style={{ display: "flex", flexDirection: "column", width: "45%" }}>
          <Typography variant="subtitle1" style={{ marginBottom: 15 }}>
            Backend Update History
          </Typography>
          {beVersion?.history?.map((log, idx) => (
            <React.Fragment key={idx}>
              <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                {" "}
                {log.version}{" "}
              </Typography>
              <ul>
                {log.update.map((upd, index) => (
                  <li key={`${idx}__${index}`}>{upd}</li>
                ))}
              </ul>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default About;
