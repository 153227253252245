import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns=[
        {
            selector:(row)=>row?.code,
            name:"IATA",
            id:'code',
            compact:true
        },
        {
            selector:(row)=>row?.icao_code,
            name:"ICAO",
            id:'icao',
            compact:true
        },
        {
            selector:(row)=>row?.name,
            name:"Name",
            id:'name'
        },
        {
            selector:(row)=>row?.kota,
            name:"City",
            id:'kota'
        },
        {
            selector:(row)=>row?.country,
            name:"Country",
            id:'country'
        },
        {
            selector:(row)=>row?.timezone,
            name:"Timezone",
            id:'timezone'
        },
        {
            selector:(row)=>(row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name:"CreatedAt",
            id:'created_at'
        },
    ]
    return (
        <TablePage
            title={"Master Airport"}
            url="api/v1/airport"
            actionPosition={'first'}
            createPath="/master/airport/create"
            editPath={"/master/airport/edit"}
            columns={columns}            
        />
    )
}