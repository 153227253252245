import { DatePicker, Button, Form } from 'antd';

import React, { useEffect, useMemo, useState } from 'react';
import MyPage from '../Page';
import moment from 'moment';

import TableSection from './TableInventory';
import FilterData from './FilterInventory';
import { FileSearchOutlined } from '@ant-design/icons'

export default ({ url, title, repType, FetchComponent, printHeaderReport, printFooterReport, onDataChange,
    onFilterChange, onDateChange }) => {
    const [timestamp, setTimestamp] = useState(moment().unix());
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(1, 'day'));
    const [selectedValues, setSelectedValues] = useState([]);
    const [filterLists, setFilterLists] = useState([]);
    const curUrl = useMemo(() => {
        return `${startDate.format('DD-MMM-YYYY')}/${endDate.format('DD-MMM-YYYY')}`;
    }, [startDate, endDate]);
    const [uri, setUri] = useState(curUrl);

    const maxEndDate = useMemo(() => {
        const nextWeek = moment(startDate).add(7, 'day');
        return (nextWeek.unix() > moment().add(1, 'day').unix()) ? moment().add(1, 'day') : nextWeek;
    }, [startDate]);

    useEffect(() => {
        if (endDate.startOf('date').unix() > maxEndDate.startOf('date').unix()) {
            setEndDate(maxEndDate);
        }
    }, [maxEndDate])

    useEffect(() => {
        onFilterChange && typeof onFilterChange === 'function' && onFilterChange(selectedValues);
    }, [selectedValues])

    useEffect(() => {
        onDateChange && typeof onDateChange === 'function' && onDateChange(startDate, endDate);
    }, [startDate, endDate])
    return (
        <MyPage title={title}>
            <div style={{ display: 'flex', flexDirection: 'column', paddingBlock: 30 }}>
                <FilterData
                    lists={filterLists}
                    idReport={`${url}${repType}`}
                    onFilterValueChange={fval => {
                        setSelectedValues(fval);
                    }}
                />
                <TableSection
                    date={uri}
                    baseUrl={url}
                    FetchComponent={FetchComponent}
                    repType={repType}
                    timestamp={timestamp}
                    selectedValue={selectedValues}
                    setFilterLists={setFilterLists}
                    printHeader={printHeaderReport}
                    printFooter={printFooterReport}
                    onDataChange={onDataChange}
                    title={title}
                />
            </div>
        </MyPage>
    )
}