import React, { useState } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import FormTps from "./form";

export default ({ data, setLocalRefresh }) => {
    const { awbs } = data;

    const [visible, setVisible] = useState(false);
    const [selecData, setSelecData] = useState(null);
    const [hoveredRow, setHoveredRow] = useState(null);

    const showModalSend = (data) => {
        setVisible(true);
        setSelecData(data)
    };
    const closeModalSend = () => {
        setVisible(false);
        setSelecData(null)
    };

    return (
        <>
            <Modal
                title="Form Send Import In"
                visible={visible}
                onCancel={closeModalSend}
                footer={null}
                width={"70%"}
                destroyOnClose
            >
                <FormTps data={selecData} modalClose={closeModalSend} setLocalRefresh={setLocalRefresh} />
            </Modal>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 20 }}>
                <table style={{ border: '1px solid black' }}>
                    <tr style={{ border: '1px solid black' }}>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>No</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>Tanggal</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>Awb Number</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>Hawb Number</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>Pieces</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>Weight</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>Route</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>Consignee</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>NO BC11</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>TGL BC11</th>
                        <th style={{ border: '1px solid black', fontSize: 11 }}>WK INOUT</th>
                    </tr>
                    {awbs && Array.isArray(awbs) && awbs.map((item, idx) => (
                        <>
                            <tr
                                onClick={() => showModalSend(item)}
                                key={item._id}
                                onMouseEnter={() => setHoveredRow(item._id)}
                                onMouseLeave={() => setHoveredRow(null)}
                                style={{
                                    cursor: 'pointer', border: '1px solid black',
                                    backgroundColor: hoveredRow === item._id ? '#cccccc' : `${item?.import_in_params?.wk_inout ? "green" : "white"}`,
                                    cursor: 'pointer'
                                }}
                            >
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{idx + 1}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{moment(item?.createdAt).format('DD-MMM-YYYY')}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.mawb_number}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.hawb_number}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.jml_kemas}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.bruto}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.origin?.code} - {item?.dest?.code}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.exportir_name}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.no_bc11}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{moment(item?.tgl_bc11).format('DD-MMM-YYYY')}</td>
                                <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.import_in_params?.wk_inout ? moment(item?.import_in_params?.wk_inout, "YYYYMMDDHHmmssSS").format("DD-MMM-YYYY HH:mm:ss") : "-"}</td>
                            </tr >
                        </>
                    ))}
                </table>
            </div >
        </>
    )
}
