import React from 'react';
import { Tag, Tooltip, message } from 'antd';
import TablePage from '../../../../../../components/pages/TablePage';
import kdDocs from '../../../../../../assets/kode_dokument.json';
import DetailPage from './detailLog';
import moment from 'moment';

export default () => {

    function getNameByValue(value) {
        if (value === null || value === undefined) {
            return "";
        }

        const item = kdDocs.find(item => item.value === value);
        return item ? `${item.name.trim()}` : "";
    }

    const columns = [
        {
            selector: (row) => <>
                {
                    row.status === "Gate In" ?
                        <Tag color="green">{row.status}</Tag> :
                        <>
                            {
                                row.status === "Gate Out" ?
                                    <Tag color="blue">{row.status}</Tag> :
                                    <Tag color="red">{row.status}</Tag>
                            }
                        </>
                }
            </>,
            width: "100px",
            name: "Status",
            id: 'status'
        },
        {
            selector: (row) =>
                <Tooltip placement="top" title="Copy">
                    <span style={{ color: "red", cursor: "pointer" }} onClick={() => {
                        navigator.clipboard.writeText(row?.reff_number)
                        message.success('Berhasil di Copy');
                    }}>{row?.reff_number}</span>
                </Tooltip>,
            name: "Reff Number",
            width: "150px",
            id: 'reff_number',
            wrap: true,
        },
        {
            selector: (row) => row?.no_bc11 || "-",
            name: "NO BC11",
            id: 'no_bc11'
        },
        {
            selector: (row) => row?.tgl_bc11 || "-",
            name: "TGL BC11",
            id: 'tgl_bc11'
        },
        {
            selector: (row) => row?.wk_inout || "-",
            name: "Waktu Inout",
            id: 'wk_inout',
            width: "150px"
        },
        {
            selector: (row) => row?.no_dok_inout,
            name: "No Doc",
            id: 'no_dok_inout',
            width: "150px"
        },
        {
            selector: (row) => getNameByValue(row?.kd_dok_inout),
            name: "Type Doc",
            id: 'no_dok_inout'
        },
        {
            selector: (row) => row?.mawb_number,
            name: "MAWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => <span>{row?.awb_id?.origin?.code} - {row?.awb_id?.dest?.code}</span>,
            name: "Route",
            id: 'state',
            width: "100px"
        },
        {
            selector: (row) => row?.awb_id?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.awb_id?.flight_date,
            name: "FL Date",
            id: 'flight_date'
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Exp Name",
            id: 'exportir_name',
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY')) || '-',
            name: "Tanggal AWB",
            id: 'createdAt'
        },
    ]
    return (
        <TablePage
            title={"Export List"}
            url="api/v1/tps_export/list"
            actionPosition={'right'}
            columns={columns}
            ExpandComponent={DetailPage}
        />
    )
}