import React from 'react';
import { Descriptions } from 'antd';

export default ({ data }) => {
    const { msg_source, agent, shipper, consigne } = data;
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
            <div style={{ display: "flex" }}>
                <div style={{ width: '60%' }}>
                    <p>1212</p>
                    <Descriptions
                        vertical
                        title="Agent"
                        size="small"
                        contentStyle={{ fontSize: 14 }}
                        labelStyle={{ fontSize: 14 }}
                    >
                        <Descriptions.Item label="Name">{agent?.name} ({agent?.account_number || '-'})</Descriptions.Item>
                        <Descriptions.Item label="IATA Code">{agent?.iata_cargo_agent_num_code}</Descriptions.Item>
                        <Descriptions.Item label="Address">{agent?.place}</Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        vertical
                        title="Shipper"
                        size="small"
                        contentStyle={{ fontSize: 14 }}
                        labelStyle={{ fontSize: 14 }}
                    >
                        <Descriptions.Item label="Name">{shipper?.name} ({shipper?.account_number || '-'})</Descriptions.Item>
                        <Descriptions.Item label="Address">{shipper?.location}</Descriptions.Item>
                    </Descriptions>

                    <Descriptions
                        vertical
                        title="Consignee"
                        size="small"
                        contentStyle={{ fontSize: 14 }}
                        labelStyle={{ fontSize: 14 }}
                    >
                        <Descriptions.Item label="Name">{consigne?.name}</Descriptions.Item>
                        <Descriptions.Item label="Address">{consigne?.location}</Descriptions.Item>
                    </Descriptions>
                </div>
                <pre style={{ marginLeft: 32, fontSize: 10 }}>
                    {
                        msg_source?.message.join('\n')
                    }
                </pre>
            </div>
        </div>
    )
}