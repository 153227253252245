import { Form, Input, Row, Col, DatePicker, InputNumber, Select, Badge } from 'antd';
import React, { useState } from 'react';
import FormPage from '../../../../../../components/pages/FormPage';
import { useSelector } from 'react-redux';
import moment from 'moment';
import GateInData from './gatein';

const DefModel = {
    _id: null,
    tipe_data: '',
    no_surat: '',
    tgl_surat: moment(),
    kd_tps_asal: '',
    gudang_asal: '',
    kd_tps_tujuan: '',
    gudang_tujuan: '',
    kd_alasan_plp: '',
    yor_asal: '',
    yor_tujuan: '',
    call_sign: '',
    nm_angkut: '',
    no_voy_flight: '',
    tgl_tiba: moment(),
    no_bc11: '',
    tgl_bc11: moment(),
    nm_pemohon: '',
    jns_kemas: '',
    jml_kemas: '',
    no_bl_awb: '',
    tgl_bl_awb: moment(),
    isCreate: true
}

export default () => {
    const [model, setModel] = useState(DefModel);

    const handleDateChange = (name, date, dateString) => {
        const formattedDate = moment(date).format('YYYYMMDD');
        setModel((prevState) => ({ ...prevState, [name]: formattedDate }));
    };

    return (
        <Row gutter={24}>
            <Col span={12}>
                <GateInData setModel={setModel} model={model} />
            </Col>
            <Col span={12}>
                <FormPage
                    url={'api/v1/plp/permohonan'}
                    callbackPath={"/tps/plp/permohonan"}
                    formValid={false}
                    model={model}
                    layout="vertical"
                    setModel={setModel}
                    formSize="default"
                    title={"Permohonan PLP"}
                    emptyModel={DefModel}
                    onSaveEvent={(model) => {
                        const { tgl_surat, tgl_tiba, tgl_bc11, tgl_bl_awb, no_bl_awb, ...rest } = model;
                        return {
                            ...rest,
                            tgl_surat: moment(tgl_surat).format('YYYYMMDD'),
                            tgl_tiba: moment(tgl_tiba).format('YYYYMMDD'),
                            tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'),
                            no_bl_awb: no_bl_awb.split('-').join(''),
                            tgl_bl_awb: moment(tgl_bl_awb).format('YYYYMMDD'),
                        }
                    }}
                >
                    <Row gutter={24} style={{ width: '100%' }}>
                        <Col span={12}>
                            <Form.Item label="No Surat" name={'no_surat'} rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Tipe Data" name={'tipe_data'} rules={[{ required: true }]}>
                                <Select
                                    options={[
                                        {
                                            value: 1,
                                            label: '1: Baru',
                                        },
                                        {
                                            value: 2,
                                            label: '2: Manual',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="TGL Surat">
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD-MMM-YYYY"
                                    value={moment(model?.tgl_surat)}
                                    onChange={(date, dateString) => handleDateChange("tgl_surat", date, dateString)}
                                    clearIcon={false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="KD TPS Asal" name={'kd_tps_asal'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="KD TPS Tujuan" name={'kd_tps_tujuan'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Gudang Asal" name={'gudang_asal'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Gudang Tujuan" name={'gudang_tujuan'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="KD Alasan PLP" name={'kd_alasan_plp'} rules={[{ required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="Select a person"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={[
                                        {
                                            value: 1,
                                            label: "1: YOR/SOR TPS sama dengan atau lebih tinggi dari batas standar utilisasi fasilitas yang ditetapkan oleh instansi teknis yang bertanggung jawab di bidang pelabuhan.",
                                        },
                                        {
                                            value: 2,
                                            label: '2: pada TPS asal tidak tersedia tempat penimbunan barang impor konsolidasi, yaitu pengangkutan barang impor menggunakan satu peti kemas untuk lebih dari satu penerima barang atau menggunakan satu peti kemas untuk lebih dari satu house bill of lading dengan penerima barang yang sama (Less than container load = LCL).',
                                        },
                                        {
                                            value: 3,
                                            label: '3: pada TPS asal tidak tersedia tempat penimbunan barang impor yang karena sifatnya membutuhkan sarana dan prasarana penyimpanan atau penumpukan khusus yang tersedia mencapai sama dengan atau lebih tinggi dari batas standar utilisasi fasilitas',
                                        },
                                        {
                                            value: 4,
                                            label: '4: barang impor berupa barang kena cukai yang akan dilekati pita cukai di TPS tujuan;',
                                        },
                                        {
                                            value: 5,
                                            label: '5: barang impor konsolidasi dalam 1(satu) masterairway bill atau master bill of lading;',
                                        },
                                        {
                                            value: 6,
                                            label: '6: berdasarkan pertimbangan Kepala Kantor Pabean dimungkinkan terjadi stagnasi setelah mendapatkan masukan dari pengusaha TPS Asal.',
                                        },
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Yor Asal" name={'yor_asal'}>
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Yor Tujuan" name={'yor_tujuan'}>
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="Call Sign" name={'call_sign'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Nama Angkut" name={'nm_angkut'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No Voy Flight" name={'no_voy_flight'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="TGL Tiba">
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD-MMM-YYYY"
                                    value={moment(model?.tgl_tiba)}
                                    onChange={(date, dateString) => handleDateChange("tgl_tiba", date, dateString)}
                                    clearIcon={false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="No BC11" name={'no_bc11'} rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item label="TGL BC11">
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD-MMM-YYYY"
                                    value={moment(model?.tgl_bc11)}
                                    onChange={(date, dateString) => handleDateChange("tgl_bc11", date, dateString)}
                                    clearIcon={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="NAMA Pemohon" name={'nm_pemohon'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="JNS kemas" name={'jns_kemas'} rules={[{ required: true }]}>
                                <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="JML Kemas" name={'jml_kemas'} rules={[{ required: true }]}>
                                <InputNumber style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="No BL AWB" name={'no_bl_awb'} rules={[{ required: true }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="TGL BL AWB" >
                                <DatePicker
                                    style={{ width: '100%' }}
                                    format="DD-MMM-YYYY"
                                    value={moment(model?.tgl_bl_awb)}
                                    onChange={(date, dateString) => handleDateChange("tgl_bl_awb", date, dateString)}
                                    clearIcon={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </FormPage>
            </Col>
        </Row>
    )
}