import React, { useState } from 'react'
import { Modal, Form, Input, Select, Button, Row, Col, DatePicker, message } from 'antd';
import { PostAPI } from '../../../../../redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
const { Option } = Select;

export default ({ isModalOpen, handleCancel, isDataModal, setRefresh }) => { // eslint-disable-line

    const dispatch = useDispatch()
    const [dataDocument, setDataDocument] = useState(null)
    const [docType, setDocType] = useState(null) // eslint-disable-line
    const [form] = Form.useForm();

    const findDocument = (value) => {
        dispatch(PostAPI({ url: 'api/v1/tps_distribusi/verify', data: { ...value, doc_date: moment(value?.doc_date).format("DDMMYYYY") } }))
            .then(resp => {
                if (resp.payload?.message === undefined) {
                    setDataDocument({ ...resp.payload, doc_type: value?.current_kd_dok })
                } else {
                    setDataDocument(null)
                    message.error('Tidak Menemukan Data');
                }
            })
    }

    const saveDocument = () => {
        dispatch(PostAPI({ url: 'api/v1/tps_distribusi/save_verified', data: { ...dataDocument, _id: isDataModal?._id } }))
            .then(resp => {
                setRefresh(moment().unix())
                handleCancel()
                message.success('berhasil verifikasi');
            })
    }

    const closeModal = () => {
        setDataDocument(null)
        setDocType(null)
        handleCancel()
        form.resetFields();
    }

    const handleChange = (value) => {
        setDocType(value)
    };

    return (
        <div>
            <Modal title={isDataModal?.mawb_number} visible={isModalOpen} onCancel={closeModal} footer={null} width={800}>
                <Row justify="space-around">
                    <Col span={12}>
                        <Form
                            layout='vertical'
                            onFinish={findDocument}
                            form={form}
                        >
                            <Form.Item
                                name="current_kd_dok"
                                label="Type Document"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Select
                                    allowClear
                                    onChange={handleChange}
                                >
                                    <Option value="SPPB">SPPB</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name="no_dok_inout"
                                label="No Document"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name="doc_date"
                                label="Tanggal Doc"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: '100%' }} placeholder="" />
                            </Form.Item>
                            <Form.Item
                                name="exportir_npwp"
                                label="NPWP"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Verify Document
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                    {dataDocument === null ?
                        <></> :
                        <Col span={12} >
                            <table style={{ marginLeft: 30 }}>
                                <tr>
                                    <td style={{ width: 100 }}>AWB No</td>
                                    <td>: {dataDocument?.awb_number}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>AWB Date</td>
                                    <td>: {dataDocument?.awb_date}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>HAWB No</td>
                                    <td>: {dataDocument?.hawb_number}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>HAWB Date</td>
                                    <td>: {dataDocument?.hawb_date}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Name IMP</td>
                                    <td>: {dataDocument?.nama_imp}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Alamat IMP</td>
                                    <td>: {dataDocument?.alamat_imp}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>NPWP IMP</td>
                                    <td>: {dataDocument?.npwp_imp}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Name PPJK</td>
                                    <td>: {dataDocument?.nama_ppjk}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Alamat PPJK</td>
                                    <td>: {dataDocument?.alamat_ppjk}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>NPWP PPJK</td>
                                    <td>: {dataDocument?.npwp_ppjk}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Weight</td>
                                    <td>: {dataDocument?.bruto}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>Pieces</td>
                                    <td>: {dataDocument?.jml_kms}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No BC11</td>
                                    <td>: {dataDocument?.no_bc11}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No Pos BC11</td>
                                    <td>: {dataDocument?.no_pos_bc11}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>TGL BC11</td>
                                    <td>: {dataDocument?.tgl_bc11}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No SPPB</td>
                                    <td>: {dataDocument?.no_sppb}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>TGL SPPB</td>
                                    <td>: {dataDocument?.tgl_sppb}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>No PIB</td>
                                    <td>: {dataDocument?.no_pib}</td>
                                </tr>
                                <tr>
                                    <td style={{ width: 100 }}>TGL PIB</td>
                                    <td>: {dataDocument?.tgl_pib}</td>
                                </tr>

                            </table>
                            <Button type="primary" style={{ marginLeft: 32, width: '90%', marginTop: 10 }} onClick={() => saveDocument()}>
                                Save
                            </Button>
                        </Col>
                    }
                </Row>
            </Modal>
        </div>
    )
}