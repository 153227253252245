import { Form, Input, Row, Col, Select, DatePicker } from "antd";
import React, { useState } from "react";
import FormPage from "../../../../components/pages/FormPage";
import { useSelector } from "react-redux";
import moment from "moment";
import numeral from "numeral";

const DefModel = {
    airline_id: "",
    flight_number: "",
    flight_date_str: "",
    origin: "",
    dest: "",
    isCreate: true
};

export default () => {
    const [model, setModel] = useState(DefModel);
    const { airlineCache, airportCache } = useSelector(state => state.apps)

    const normalizeFlightNumber = (fnumber) => {
        const carrier = fnumber.substr(0, 2);
        const number = fnumber.substr(2);
        if (!!parseInt(number)) {
            return carrier + numeral(number).format('0000');
        }
        return fnumber;
    }

    return (
        <FormPage
            url={"api/v1/flight_record"}
            callbackPath={"/home"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Flight"}
            onSaveEvent={(model) => {
                const { airline_id, flight_number, flight_date_str, origin, dest, ...resp } = model;
                const aln = airlineCache.find((airline) => airline.name === airline_id);
                const org = airportCache.find((airport) => airport.code === origin);
                const dst = airportCache.find((airport) => airport.code === dest);
                const fn = normalizeFlightNumber(flight_number);

                return {
                    ...resp,
                    flight_date_str: moment(flight_date_str).format('DD-MMM-YYYY'),
                    airline_id: aln,
                    origin: org,
                    dest: dst,
                    flight_number: fn,
                    carrier: fn.slice(0, 2),
                    number: fn.slice(2),
                    unixStd: moment().unix(),
                    status: "Scheduled"
                };
            }}
        >
            <Row gutter={12} style={{ width: "30%" }}>
                <Col span={24}>
                    <Form.Item name={"airline_id"} label="Airline" rules={[{ required: true }]} >
                        <Select
                            showSearch
                            autoFocus
                        >
                            {airlineCache.map(role => (
                                <Select.Option key={role._id} value={role.name}>
                                    {role.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={"flight_number"} label="Flight Number" rules={[{ required: true }]} >
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Flight Date" name={'flight_date_str'} rules={[{ required: true }]}>
                        <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={"origin"} label="Origin" rules={[{ required: true }]} >
                        <Select
                            showSearch
                        >
                            {airportCache.map(role => (
                                <Select.Option key={role._id} value={role.code}>
                                    {role.code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item name={"dest"} label="Destination" rules={[{ required: true }]} >
                        <Select
                            showSearch
                        >
                            {airportCache.map(role => (
                                <Select.Option key={role._id} value={role.code}>
                                    {role.code}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}
