import moment from 'moment';
import React, { useState } from 'react';
import { Form, Input, Row, Col, Divider, DatePicker, Badge, Select } from 'antd';
import FormPage from '../../../../../../components/pages/FormPage2';
import kdKemas from '../../../../../../assets/kode_kemas.json';

const { Option } = Select;
const DefModel = {
    wk_inout: moment().format('YYYYMMDDHHmmss'),
    tgl_tiba: moment().format('YYYYMMDD'),
    seri_kemas: '',
    cont_asal: ''
}

export default ({ data, modalClose, setRefresh }) => {
    const [model, setModel] = useState({ ...DefModel, ...data });

    const handleDateChange = (name, date, dateString) => {
        const formattedDate = moment(date).format('YYYYMMDD');
        setModel((prevState) => ({ ...prevState, [name]: formattedDate }));
    };

    const options = kdKemas.map(item => ({
        ...item,
        name: `${item?.value}, ${item?.name.trim()}`
    }));

    return (
        <FormPage
            url={'api/v1/tps_export/export_in'}
            formValid={false}
            model={model}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Form Export In"}
            emptyModel={DefModel}
            modalClose={modalClose}
            onSaveEvent={(model) => {
                const {
                    _id, tgl_tiba, hawb_number, no_dok_inout, mawb_number, tgl_dok_inout,
                    exportir_npwp, exportir_name, peb_number, kode_kemas, peb_date
                } = model;
                return {
                    _id, tgl_tiba, hawb_number, no_dok_inout, mawb_number, tgl_dok_inout,
                    exportir_npwp, exportir_name, peb_number, kode_kemas, peb_date
                }
            }}
            finisEvent={() => {
                modalClose();
                setRefresh(moment().unix());
            }}
        >
            <Row gutter={16} style={{ width: '50%' }}>
                <Divider>HEADER</Divider>
                <Col span={12}>
                    <Form.Item label="KD DOK">
                        <Input autoComplete="off" defaultValue={2} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="CALL SIGN">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_record_id?.airline_id?.code} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD TPS" name={"kode_tps"}>
                        <Input autoComplete="off" defaultValue={model?.kode_tps} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL KEBERANGKATAN
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.tgl_tiba)}
                            onChange={(date, dateString) => handleDateChange("tgl_tiba", date, dateString)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="NM ANGKUT">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_record_id?.airline_id?.name} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD GUDANG" name={"kode_gudang_ekspor"}>
                        <Input autoComplete="off" defaultValue={model?.kode_gudang_ekspor} readOnly />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="NO NOVY FLIGHT">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_number} readOnly />
                    </Form.Item>
                </Col>

                <Divider>DETAIL</Divider>
                <Col span={8}>
                    <Form.Item label={
                        <>
                            HAWB NUMBER
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={"hawb_number"}>
                        <Input autoComplete="off" defaultValue={model?.hawb_number} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="KD DOK INOUT" name={"kd_dok_inout"}>
                        <Input autoComplete="off" defaultValue={model?.kd_dok_inout} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="NO IJIN TPS" name={'no_izin_tps'}>
                        <Input autoComplete="off" defaultValue={model?.no_izin_tps} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="HAWB DATE">
                        <Input autoComplete="off" defaultValue={!!model?.hawb_date ? moment(model.hawb_date).format("YYYYMMDD") : null} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={
                        <>
                            NO DOK INOUT
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={"no_dok_inout"}>
                        <Input autoComplete="off" defaultValue={model?.no_dok_inout} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="TGL IJIN TPS">
                        <Input autoComplete="off" defaultValue={`${moment(model?.tgl_izin_tps).format('DD-MMM-YYYY')}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            MAWB NUMBER
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'mawb_number'}>
                        <Input autoComplete="off" defaultValue={model?.mawb_number} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL DOK INOUT
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.tgl_dok_inout)}
                            onChange={(date, dateString) => handleDateChange("tgl_dok_inout", date, dateString)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="MAWB DATE">
                        <Input autoComplete="off" defaultValue={!!model?.mawb_date ? moment(model.mawb_date).format('YYYYMMDD') : null} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            NPWP CONSIGNEE
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={"exportir_npwp"}>
                        <Input maxLength={15} autoComplete="off" defaultValue={model?.exportir_npwp} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD SAR ANGKUT INOUT">
                        <Input autoComplete="off" defaultValue="4" readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            CONSIGNEE
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={"exportir_name"}>
                        <Input autoComplete="off" defaultValue={model?.exportir_name} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="BRUTO">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.weight} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KOLI">
                        <Input autoComplete="off" defaultValue={model?.awb_id?.pieces} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="NO BC11">
                        <Input autoComplete="off" defaultValue="" readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL BC11">
                        <Input autoComplete="off" defaultValue="" readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="PEL MUAT">
                        <Input autoComplete="off" defaultValue={`${model?.awb_id?.origin?.country}${model?.awb_id?.origin?.code}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="PEL BONGKAR">
                        <Input autoComplete="off" defaultValue={`${model?.awb_id?.dest?.country}${model?.awb_id?.dest?.code}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KODE KANTOR" name={"kode_kantor"}>
                        <Input autoComplete="off" defaultValue={`${model?.kode_kantor}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            NO DAFTAR PABEAN
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={"peb_number"}>
                        <Input maxLength={6} autoComplete="off" defaultValue={`${model?.peb_number}`} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={"KODE KEMAS"}
                        name={"kode_kemas"}>
                        <Select
                            showSearch
                            placeholder=""
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.children?.toLowerCase().includes(input.toLowerCase())
                            }
                            open={false}
                            onDropdownVisibleChange={() => false}
                        >
                            {options.map((option) => (
                                <Option key={option.value} value={option.value} label={option.name}>
                                    {option.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL DAFTAR PABEAN
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.peb_date)}
                            onChange={(date, dateString) => handleDateChange("peb_date", date, dateString)}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    )
}