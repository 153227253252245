import { Button, Result } from 'antd';
import { useHistory } from 'react-router-dom';

export default () => {
    const history = useHistory();

    const handleGoHome = () => {
        history.push('/');
    };
    return (
        <Result
            status="404"
            title="404"
            subTitle="Maaf, halaman yang Anda kunjungi tidak ada.."
            extra={<Button type="primary" onClick={() => handleGoHome()}>Kembali</Button>}
        />
    )
}