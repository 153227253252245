import React, { Fragment, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Button } from 'antd';
import Barcode from 'react-barcode';

class PrintBppOut extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }
    }

    render() {
        const { data } = this.state;
        const { awb_number, hawb_number } = data;

        return (
            <body style={{ fontSize: 16, color: "black" }}>
                <p>Airwaybill</p>
                <Barcode height={60} width={2} fontSize={10} value={awb_number} margin={0} /><br />
                <p>House Airwaybill</p>
                <Barcode height={60} width={2} fontSize={10} value={hawb_number} margin={0} /><br />
            </body>
        );
    }
}

const PrintAwbExp = ({ data, icon, iconBtnProps, title }) => {
    const theRef = useRef();

    return (
        <React.Fragment>
            <ReactToPrint
                trigger={() => (
                    <Button
                        size="small"
                        variant="text"
                        color="primary"
                        startIcon={icon}
                        {...iconBtnProps}
                    >
                        {title}
                    </Button>
                )}
                documentTitle={`AWB`}
                content={() => theRef.current}
            />
            <div style={{ display: "none" }}>
                <PrintBppOut data={{ ...data }} ref={theRef} />
            </div>
        </React.Fragment>
    );
};

export default PrintAwbExp;
