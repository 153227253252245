import React from 'react';

export default ({ data }) => {
    const { message, double_sign, priority, destination, origin, msgid } = data;

    return (
        <div style={{ padding: '4px 32px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    <p variant="subtitle1">Messages </p>
                </div>
                {
                    !!double_sign && (<div>
                        <p align="right" variant="body2">Double Sign</p>
                        <p align="right" variant="body2">{double_sign}</p>
                    </div>)
                }
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', fontSize: 10, padding: 10 }}>
                <p variant="body2">{((priority?.code) && (priority?.code?.toLowerCase() !== 'unknown')) ? priority?.code + ' ' : ''}{destination.join(' ')}</p>
                <p variant="body2">.{origin} {msgid}</p>
                {
                    message?.map((txt, idx) => (
                        <div key={idx}>
                            {((idx > 0) && (idx % 49) === 0) && (<div className="page-break"></div>)}
                            <p variant="body2" component={"pre"} style={{ fontFamily: 'monospace', whiteSpace: 'pre-wrap' }}>{txt}</p>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
