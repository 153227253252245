import React, { Fragment } from 'react'
import TablePage from '../../../../../../components/pages/TablePage';
import { Button, Tabs } from 'antd';
import Report from './report';
import DetailPage from './detail';
import moment from 'moment';
import { FiEdit } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

export default () => {
    const history = useHistory();

    const columns = [
        {
            selector: (row) => row?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "Flight Date",
            id: 'flight_date'
        },
        {
            selector: (row) => row?.no_bc11,
            name: "NO BC11",
            id: 'no_bc11'
        },
        {
            selector: (row) => moment(row?.tgl_bc11).format('DD-MMM-YYYY'),
            name: "TGL BC11",
            id: 'tgl_bc11'
        },
        {
            name: "Action",
            id: 'tgl_bc11',
            selector: (row) => {
                const { awbs, no_bc11, tgl_bc11 } = row;
                return (
                    <Button
                        icon={<FiEdit size={18} color="black" />}
                        style={{ paddingInline: 8 }}
                        shape={'round'}
                        type="link"
                        onClick={() => history.push({ pathname: '/tps/import/flight_editform', state: { flight_id: awbs[0]?.flight_record_id?._id, no_bc11, tgl_bc11 } })}
                    />
                )
            },
        },
    ]
    return (
        <Fragment>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Airwaybill" key="1">
                    <TablePage
                        title={"Import Airwaybill"}
                        url="api/v1/import_awb"
                        actionPosition={'first'}
                        columns={columns}
                        createPath="/tps/import/awb_form"
                        ExpandComponent={DetailPage}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Report" key="2">
                    <Report />
                </Tabs.TabPane>
            </Tabs>
        </Fragment>
    )
}