import React from 'react'
import TablePage from '../../../../../../components/pages/TablePage'
import moment from 'moment'

export default () => { // eslint-disable-line
    const columns = [
        {
            selector: (row) => row?.mawb_number,
            name: "Master AWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => <span>{row?.origin?.code} - {row?.dest?.code}</span>,
            name: "Route",
            id: 'state'
        },
        {
            selector: (row) => row?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "FL Date",
            id: 'flight_date_str'
        },
        {
            selector: (row) => row?.shp_npwp,
            name: "Shipper ID",
            id: 'shp_npwp',
            wrap: true,
        },
        {
            selector: (row) => row?.shp_name,
            name: "Shipper Name",
            id: 'shp_name',
            wrap: true,
        },
        {
            selector: (row) => row?.exportir_npwp,
            name: "Exportir ID",
            id: 'exportir_npwp',
            wrap: true,
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Exportir Name",
            id: 'exportir_name',
            wrap: true,
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "Created At",
            id: 'created_at'
        },
    ]
    return (
        <TablePage
            title={"Distribusi In"}
            url="api/v1/tps_distribusi/distribusi_in"
            actionPosition={'right'}
            editPath={"/tps/distribusi/distribusi_in/form"}
            columns={columns}
        />
    )
}