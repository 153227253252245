import { Form, Input, Row, Col, Divider, DatePicker, Badge, InputNumber } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../../components/pages/FormPage2'
import moment from 'moment';
import numeral from 'numeral';

const DefModel = {
    tgl_dok_inout: moment().format('YYYYMMDD'),
    wk_inout: moment().format('YYYYMMDDHHmmss'),
    tgl_tiba: moment().format('YYYYMMDD'),
    tgl_tiba_out: moment(),
    hawb_date: moment().format('YYYYMMDD'),
    mawb_date_out: moment(),
    peb_date: moment().format('YYYYMMDD'),
    tgl_segel_bc: moment().format('YYYYMMDD'),
    no_segel_bc: '',
    cont_asal: '',
    no_do: '',
    kd_dok_inout: '',
    no_dok_inout: '',
    peb_number: '',
}

export default () => { // eslint-disable-line
    const [model, setModel] = useState({ ...DefModel });

    const handleDateChange = (name, date, dateString) => {
        const formattedDate = moment(date).format('YYYYMMDD');
        setModel((prevState) => ({ ...prevState, [name]: formattedDate }));
    };

    const formatAwbNumber = (value) => {
        let str = value;
        if (str.length > 4 && str.substring(3, 4) !== '-') {
            str = str.substring(0, 3) + '-' + str.substring(3);
        }
        return str
    }

    return (
        <FormPage
            url={'api/v1/tps_distribusi/distribusi_out'}
            formValid={false}
            model={model}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Form Distribusi In"}
            emptyModel={DefModel}
            callbackPath={"/tps/distribusi/distribusi_out"}
            onSaveEvent={(model) => {
                const { kode_kemas, tgl_tiba_out, mawb_date_out, hawb_date_out, ...rest } = model;
                return {
                    ...rest,
                    kode_kemas: kode_kemas.toUpperCase(),
                    mawb_date_out: moment(mawb_date_out).format('YYYYMMDD'),
                    hawb_date_out: moment(hawb_date_out).format('YYYYMMDD'),
                    tgl_tiba_out: moment(tgl_tiba_out).format('YYYYMMDD')
                }
            }}
        >
            <Row gutter={16} style={{ width: '50%' }}>
                <Divider>HEADER</Divider>
                <Col span={8}>
                    <Form.Item label="KD DOK">
                        <Input autoComplete="off" defaultValue={4} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="CALL SIGN">
                        <Input autoComplete="off" defaultValue={model?.flight_record_id?.airline_id?.code} readOnly />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="KD TPS" name={"kode_gudang_distribusi"}>
                        <Input autoComplete="off" defaultValue={model?.kode_gudang_distribusi} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD GUDANG" name={"kode_gudang_distribusi"}>
                        <Input autoComplete="off" defaultValue={model?.kode_gudang_distribusi} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="REF NUMBER">
                        <Input autoComplete="off" defaultValue={`${model?.kode_tps}${moment().format('YYMMDD')}${numeral(model?.no_urut_distribusi + 1).format('000000')}`} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NM ANGKUT IN">
                        <Input autoComplete="off" defaultValue={model?.flight_record_id?.airline_id?.name} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="NO NOVY FLIGHT IN">
                        <Input autoComplete="off" defaultValue={model?.flight_record_id?.flight_number} readOnly />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item>
                        <Form.Item rules={[
                            {
                                required: true,
                            },
                        ]} label={
                            <>
                                NM ANGKUT AIRLINE
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={'nm_angkut_out'}>
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label={
                            <>
                                FLIGHT NUMBER
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={'flight_number_out'}
                    >
                        <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label={
                            <>
                                TGL KEBERANGKATAN
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.tgl_tiba_out)}
                            onChange={(date, dateString) => handleDateChange("tgl_tiba_out", date, dateString)}
                            clearIcon={false}
                        />
                    </Form.Item>
                </Col>


                <Divider>DETAIL</Divider>

                <Col span={12}>
                    <Form.Item label="NO MASTER BL AWB IN">
                        <Input autoComplete="off" defaultValue={model?.mawb_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL MASTER BL AWB OUT" >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.mawb_date)}
                            disabled
                            onChange={(date, dateString) => handleDateChange("mawb_date", date, dateString)}
                            clearIcon={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            NO MASTER BL AWB OUT
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'mawb_number_out'}
                        rules={[
                            {
                                required: true,
                                pattern: /^\d{3}-\d+$/,
                                message: 'Nomor mawb harus dalam format 000-0000000',
                            },
                        ]}
                        getValueFromEvent={(event) => formatAwbNumber(event.target.value)}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL MASTER BL AWB OUT" >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.mawb_date_out)}
                            onChange={(date, dateString) => handleDateChange("mawb_date_out", date, dateString)}
                            clearIcon={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="ID CONSIGNEE IN">
                        <Input autoComplete="off" defaultValue={model?.exportir_npwp} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="NAME CONSIGNEE IN">
                        <Input autoComplete="off" defaultValue={model?.exportir_name} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        initialValue={model?.exportir_npwp}
                        label={
                            <>
                                ID CONSIGNEE
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={"exportir_npwp_out"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col span={12}>

                    <Form.Item
                        initialValue={model?.exportir_name}
                        label={
                            <>
                                NAME CONSIGNEE
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={"exportir_name_out"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO HAWB IN">
                        <Input autoComplete="off" defaultValue={model?.hawb_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL HAWB IN" >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            disabled
                            value={moment(model?.hawb_date)}
                            onChange={(date, dateString) => handleDateChange("hawb_date", date, dateString)}
                            clearIcon={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            NO HAWB
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'hawb_number_out'}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL HAWB
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.hawb_date_out)}
                            onChange={(date, dateString) => handleDateChange("hawb_date_out", date, dateString)}
                            clearIcon={false}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="WK INOUT">
                        <Input autoComplete="off" defaultValue={moment().format('YYYYMMDDHHmmss')} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD SAR ANGKUT INOUT">
                        <Input autoComplete="off" defaultValue="4" readOnly />
                    </Form.Item>
                </Col>


                <Col span={12}>
                    <Form.Item label={
                        <>
                            KOLI
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'jml_kemas_out'}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            BRUTO
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'bruto_out'}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <InputNumber style={{ width: '100%' }} autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            NO BC11
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'no_bc11'}>
                        <Input autoComplete="off" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL BC11
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.tgl_bc11)}
                            onChange={(date, dateString) => handleDateChange("tgl_bc11", date, dateString)}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={
                            <>
                                NO POS BC11
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                        name={"no_pos_bc11"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue="" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        initialValue={`${model?.dest?.country}${model?.dest?.code}`}
                        label={
                            <>
                                PEL MUAT
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={"muat_out"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item>
                        <Form.Item
                            initialValue={`${model?.origin?.country}${model?.origin?.code}`}
                            label={
                                <>
                                    PEL BONGKAR
                                    <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                                </>
                            } name={"bongkar_out"}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" />
                        </Form.Item>

                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KODE KANTOR">
                        <Input autoComplete="off" defaultValue={`${model?.kode_kantor}`} readOnly />
                    </Form.Item>
                </Col>

            </Row>
        </FormPage >
    )
}