import React, { useState } from 'react';
import { Button, Popconfirm, message, Input } from 'antd';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
import { RiFileDownloadLine } from "react-icons/ri";
import Config from '../../../../../config/env';

const { BaseUrl } = Config

export default () => {
    const [inputValue, setInputValue] = useState('');

    const cancel = (e) => {
    };

    const handleChange = (e) => {
        // Update nilai input saat berubah
        setInputValue(e.target.value);
    };

    const columns = [
        {
            selector: ({ flight_number }) => flight_number,
            name: "Flight Number",
            id: 'flight_number',
            width: '150px'
        },
        {
            selector: ({ flight_date }) => flight_date,
            name: "Flight Date",
            id: 'flight_date'
        },
        {
            selector: ({ flight_number_id }) => flight_number_id?.route,
            name: "Route",
            id: 'route'
        },
        {
            selector: ({ id_data }) => id_data,
            name: "ID Data",
            id: 'id_data'
        },
        {
            selector: ({ jenis_manifest }) => jenis_manifest,
            name: "Jenis Manifest",
            id: 'jenis_manifest'
        },
        {
            selector: ({ kd_jenis_manifest }) => kd_jenis_manifest,
            name: "Kode Manifes",
            id: 'kd_jenis_manifest'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY')) || '-',
            name: "CreatedAt",
            id: 'createdAt',
            width: '150px'
        },
        {
            selector: (row) => {
                return (
                    <Popconfirm
                        title={
                            <div>
                                <p>Masukkan Number Aju:</p>
                                <Input value={inputValue} onChange={handleChange} />
                            </div>
                        }
                        onConfirm={() => {
                            const { _id } = row;
                            window.open(`${BaseUrl}rskps/download/${_id}/${inputValue}`, '__blank');
                        }}
                        onCancel={cancel}
                        okText="Download"
                        cancelText="No"
                        placement="topLeft"
                    >
                        <a href="#"><RiFileDownloadLine size={20} /></a>
                    </Popconfirm>
                )
            },
            name: "Download",
            id: 'row'
        },
    ]

    return (
        <TablePage
            title={"RKSP"}
            url="api/v1/rskp"
            columns={columns}
        />
    )
}