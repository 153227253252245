import React from 'react'
import FormReport from "../../../../../../components/pages/FormReport";
import { useFetch } from '../../../../../../hooks/useFetch';

export default () => { // eslint-disable-line

    return (
        <FormReport
            title={'Import Reports'}
            repType={'daily'}
            url={"/api/v1/report_import"}
            FetchComponent={useFetch}
        />
    )
}