import { Form, Input, Row, Col, DatePicker, InputNumber, Select } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../../components/pages/FormPage2'
import moment from 'moment';
import kdKemas from '../../../../../../assets/kode_kemas.json';
import kdDocs from '../../../../../../assets/kode_dokument.json';

const { Option } = Select;

const DefModel = {
    no_segel_bc: '',
    cont_asal: '',
    no_do: '',
    no_pol: ''
}

export default ({ data, modalClose, setLocalRefresh }) => { // eslint-disable-line
    const [model, setModel] = useState({ ...DefModel, ...data });

    const options = kdKemas.map(item => ({
        ...item,
        name: `${item?.value}, ${item?.name.trim()}`
    }));

    const docs = kdDocs?.map(item => ({
        ...item,
        name: `${item?.value}, ${item?.name.trim()}`
    }));;

    return (
        <FormPage
            url={'api/v1/tps_import/import_out'}
            formValid={false}
            model={{
                ...model,
                tgl_dok_inout: !model?.tgl_dok_inout ? moment() : moment(model?.tgl_dok_inout),
                tgl_bc11: !model?.tgl_bc11 ? moment() : moment(model?.tgl_bc11),
                peb_date: !model?.peb_date ? null : moment(model?.peb_date),
                mawb_date: !model?.mawb_date ? moment() : moment(model?.mawb_date),
                hawb_date: !model?.hawb_date ? moment() : moment(model?.hawb_date),
                tgl_do: !model?.tgl_do ? null : moment(model?.tgl_do),
                tgl_segel_bc: !model?.tgl_segel_bc ? null : moment(model?.tgl_segel_bc),
            }}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Form Import Out"}
            emptyModel={DefModel}
            callbackPath={"/tps/import/import_out"}
            modalClose={modalClose}
            isHeder={false}
            onSaveEvent={(model) => {
                const {
                    _id,
                    hawb_number,
                    hawb_date,
                    mawb_number,
                    mawb_date,
                    kd_dok_inout,
                    no_dok_inout,
                    tgl_dok_inout,
                    no_do,
                    tgl_do,
                    kode_sarana_angkut,
                    jml_kemas,
                    bruto,
                    exportir_npwp,
                    exportir_name,
                    no_bc11,
                    tgl_bc11,
                    no_pos_bc11,
                    no_pol,
                    peb_number,
                    peb_date,
                    no_segel_bc,
                    tgl_segel_bc,
                    kode_kemas,
                    wk_inout,
                } = model;
                return {
                    _id,
                    hawb_number,
                    mawb_number,
                    kd_dok_inout: kd_dok_inout.toString(),
                    no_dok_inout,
                    no_do,
                    kode_sarana_angkut,
                    jml_kemas,
                    bruto,
                    exportir_npwp,
                    exportir_name,
                    no_bc11,
                    no_pos_bc11,
                    no_pol,
                    peb_number,
                    no_segel_bc,
                    wk_inout: wk_inout ? moment(wk_inout).format('YYYYMMDDHHmmss') : null,
                    tgl_dok_inout: moment(tgl_dok_inout).format('YYYYMMDD'),
                    tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'),
                    peb_date: moment(peb_date).format('YYYYMMDD'),
                    mawb_date: moment(mawb_date).format('YYYYMMDD'),
                    hawb_date: moment(hawb_date).format('YYYYMMDD'),
                    tgl_do: moment(tgl_do).format('YYYYMMDD'),
                    tgl_segel_bc: moment(tgl_segel_bc).format('YYYYMMDD'),
                    kode_kemas: kode_kemas.toUpperCase()
                }
            }}
            finisEvent={() => {
                modalClose();
                setLocalRefresh(moment().unix());
            }}
        >
            <Row gutter={16} style={{ width: '80%' }}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label={"KODE KEMAS"}
                            name={"kode_kemas"}
                            rules={[
                                {
                                    required: true,
                                    message: "KODE KEMAS wajib di isi"
                                },
                            ]}>
                            <Select
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {options.map((option) => (
                                    <Option key={option.value} value={option.value} label={option.name}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="CONSIGNEE"
                            name={'exportir_name'}
                            rules={[
                                {
                                    required: true,
                                    message: "CONSIGNEE wajib di isi"
                                },
                            ]}
                        >
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label={"NO HAWB"}
                            name={'hawb_number'}
                            rules={[
                                {
                                    required: true,
                                    message: "NO HAWB wajib di isi"
                                },
                            ]}
                        >
                            <Input maxLength={15} autoComplete="off" defaultValue={model?.hawb_number} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TGL HAWB"}
                            name="hawb_date"
                            rules={[
                                {
                                    required: true,
                                    message: "TGL HAWB wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"NPWP CONSIGNEE"}
                            name={'exportir_npwp'}
                            rules={[
                                {
                                    required: true,
                                    message: "NPWP CONSIGNEE wajib di isi"
                                },
                            ]}
                        >
                            <Input maxLength={15} autoComplete="off" />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label={"NO MAWB"}
                            name={'mawb_number'}
                            rules={[
                                {
                                    required: true,
                                    message: "NO MAWB wajib di isi"
                                },
                            ]}
                        >
                            <Input maxLength={15} autoComplete="off" defaultValue={model?.mawb_number} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TGL MAWB"}
                            name="mawb_date"
                            rules={[
                                {
                                    required: true,
                                    message: "TGL MAWB wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label={"NO BC11"}
                            name={'no_bc11'}
                            rules={[
                                {
                                    required: true,
                                    message: "NO BC11 ajib di isi"
                                },
                            ]}
                        >
                            <Input maxLength={6} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TGL BC11"}
                            name={'tgl_bc11'}
                            rules={[
                                {
                                    required: true,
                                    message: "TGL BC11 wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label={"KOLI (PCS)"}
                            name={'jml_kemas'}
                            rules={[
                                {
                                    required: true,
                                    message: "KOLI (PCS) wajib di isi"
                                },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"NO POS BC11"}
                            name={"no_pos_bc11"}
                            rules={[
                                {
                                    required: true,
                                    message: "NO POS BC11 wajib di isi"
                                },
                            ]}
                        >
                            <Input maxLength={12} autoComplete="off" defaultValue="" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"KILO (KG)"}
                            name={'bruto'}
                            rules={[
                                {
                                    required: true,
                                    message: "KILO (KG) wajib di isi"
                                },
                            ]}
                        >
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16} style={{ marginTop: 30 }}>
                    <Col span={12}>
                        <Form.Item
                            label="KD DOK INOUT"
                            name="kd_dok_inout"
                            rules={[
                                {
                                    required: true,
                                    message: "KD DOK INOUT wajib di isi"
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.children?.toLowerCase().includes(input.toLowerCase())
                                }
                            >
                                {docs.map((option) => (
                                    <Option key={option.value} value={option.value}>
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"NO DO"}
                            name={'no_do'}
                            rules={[
                                {
                                    required: true,
                                    message: "NO DO wajib di isi"
                                },
                            ]}
                        >
                            <Input autoComplete="off" defaultValue={model?.no_do} />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TGL DO"}
                            name="tgl_do"
                            rules={[
                                {
                                    required: true,
                                    message: "TGL DO wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                                placeholder=''
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item label="NO DOK INOUT"
                            name="no_dok_inout"
                            rules={[
                                {
                                    required: true,
                                    message: "NO DOK INOUT wajib di isi"
                                },
                            ]}
                        >
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TGL DOK INOUT"}
                            name="tgl_dok_inout"
                            rules={[
                                {
                                    required: true,
                                    message: "TGL DOK INOUT wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"NO POLISI"}
                            name={'no_pol'}
                            rules={[
                                {
                                    required: true,
                                    message: "NO POLISI wajib di isi"
                                },
                            ]}
                        >
                            <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" defaultValue={model?.no_pol} />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label={"NO DAFTAR PABEAN"}
                            name={'peb_number'}
                            rules={[
                                {
                                    required: true,
                                    message: "NO DAFTAR PABEAN wajib di isi"
                                },
                            ]}
                        >
                            <Input maxLength={6} autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TGL DAFTAR PABEAN"}
                            name={'peb_date'}
                            rules={[
                                {
                                    required: true,
                                    message: "TGL DAFTAR PABEAN wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                                placeholder=''
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"KODE SARANA ANGKUTAN"}
                            name={'kode_sarana_angkut'}
                            rules={[
                                {
                                    required: true,
                                    message: "KODE SARANA ANGKUT wajib di isi"
                                },
                            ]}
                        >
                            <Select
                                options={[
                                    {
                                        value: "1",
                                        label: "1, Truck",
                                    },
                                    {
                                        value: '2',
                                        label: '2, Kereta Api',
                                    },
                                    {
                                        value: '3',
                                        label: '3, Kapal',
                                    },
                                    {
                                        value: '4',
                                        label: '4, Pesawat Udara',
                                    },
                                ]}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label={"NO SEGEL BC"}
                            name={'no_segel_bc'}
                            rules={[
                                {
                                    required: true,
                                    message: "NO SEGEL BC wajib di isi"
                                },
                            ]}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label={"TGL SEGEL BC"}
                            name="tgl_segel_bc"
                            rules={[
                                {
                                    required: true,
                                    message: "TGL SEGEL BC wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                                placeholder=''
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"WAKTU GATE OUT"}
                            name={'wk_inout'}
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: "WAKTU GATE OUT wajib di isi"
                        //     },
                        // ]}
                        >
                            <DatePicker
                                showTime
                                format={"DD-MMM-YYYY HH:mm:ss"}
                                style={{ width: '100%' }}
                                value={null}
                                allowClear={false}
                                placeholder=''
                                disabledDate={(current) => {
                                    return current && current > moment().endOf('day');
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Row>
        </FormPage>
    )
}