import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAPI, PostAPI } from "../../../../redux";
import { FaUserCircle } from "react-icons/fa";
import { Button, Typography, Form, Input } from "antd";
import ModalChangePass from "./ModalChangePass.js";
import { toast } from "react-toastify";

const Profile = () => {
  const [form] = Form.useForm();
  const { userdata } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [passwords, setPasswords] = useState({
    current: "",
    new: "",
    confirm: "",
  });
  const [OpenModalChangePass, setOpenModalChangePass] = useState(false);

  const onFinish = (values) => {
    const { new: password, current } = values;
    dispatch(
      PostAPI({
        url: "api/v1/users/change_password",
        data: { password, current, username: userdata?.username },
      })
    ).then((resp) => {
      toast.success(resp?.payload);
      clearFields();
      // setOpenModalChangePass(false);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const clearFields = () => {
    setPasswords({
      current: "",
      new: "",
      confirm: "",
    });
    form.resetFields();
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "50%",
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          padding: 40,
          borderRadius: 8,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <FaUserCircle size={100} color="grey" />
        <Typography style={{ fontWeight: "bold", margin: 10 }}>
          {userdata?.username}
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Button
            onClick={() => setOpenModalChangePass(true)}
            style={{ borderRadius: 4 }}
            type="primary"
          >
            Change Password
          </Button>
        </div>
      </div>
      <ModalChangePass
        title={"Change Password"}
        setOpen={setOpenModalChangePass}
        isOpen={OpenModalChangePass}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          labelCol={{
            span: 7,
            style: {
              textAlign: "start",
            },
          }}
          wrapperCol={{
            span: 24,
          }}
        >
          <Form.Item
            label="Current Password"
            name="current"
            initialValue={passwords.current}
            rules={[
              {
                required: true,
                message: "Please input your current password!",
              },
            ]}
          >
            <Input.Password
              value={passwords.current}
              onChange={(e) =>
                setPasswords({ ...passwords, current: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="new"
            initialValue={passwords.new}
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password
              value={passwords.new}
              onChange={(e) =>
                setPasswords({ ...passwords, new: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirm"
            initialValue={passwords.confirm}
            rules={[
              {
                required: true,
                message: "Please confirm your new password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("new") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject("The two passwords do not match!");
                },
              }),
            ]}
          >
            <Input.Password
              value={passwords.confirm}
              onChange={(e) =>
                setPasswords({ ...passwords, confirm: e.target.value })
              }
            />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              borderTop: "2px solid #f0f0f0",
              paddingTop: 10,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                style={{ borderRadius: 4 }}
                type="primary"
                htmlType="submit"
              >
                Submit
              </Button>
              <Button
                style={{ borderRadius: 4 }}
                type="default"
                onClick={() => {
                  clearFields();
                  setOpenModalChangePass(false);
                }}
              >
                Back
              </Button>
            </div>
          </Form.Item>
        </Form>
      </ModalChangePass>
    </div>
  );
};

export default Profile;
