import React, { useState } from 'react';
import moment from 'moment';
import TablePage from '../../../../../../components/pages/TablePage';
import { Tooltip, message } from 'antd';
import { RiLockUnlockLine } from 'react-icons/ri';
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../../../../../redux';

export default () => {
    const dispatch = useDispatch();
    const [refresh, setRefresh] = useState(moment().unix())

    const handleRelease = (awb_id) => {
        const data = {
            awb_id
        }

        const url = `api/v1/beacukai/release`
        dispatch(PostAPI({ url, data })).then(resp => {
            setRefresh(moment().unix())
            message.success('release awb success');
        })

        return true;
    }

    const columns = [
        {
            selector: (row) => row?.no_bc11,
            name: "No BC11",
            id: 'no_bc11'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY')) || '-',
            name: "Tanggal AWB",
            id: 'createdAt'
        },
        {
            selector: (row) => row?.mawb_number,
            name: "Master AWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => <span>{row?.origin?.code} - {row?.dest?.code}</span>,
            name: "Route",
            id: 'state'
        },
        {
            selector: (row) => row?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "FL Date",
            id: 'flight_date_str'
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Exportir Name",
            id: 'exportir_name',
            wrap: true,
        },

        {
            selector: (row) => row?.jml_kemas || 0 + " Pcs",
            name: "Piecies",
            id: 'jml_kemas',
            wrap: true,
        },
        {
            selector: (row) => row?.bruto || 0 + " Kg",
            name: "Weight",
            id: 'bruto',
            wrap: true,
        },

        {
            selector: (row) => {
                return (
                    <Tooltip title="release awb">
                        <RiLockUnlockLine style={{ marginLeft: 20, cursor: "pointer", color: "green" }} size={20} onClick={() => handleRelease(row?._id)} />
                    </Tooltip>
                )
            },
            name: "Action",
            id: 'created_at'
        }
    ]
    return (
        <TablePage
            title={"Release Awb"}
            url="api/v1/beacukai/release"
            actionPosition={'right'}
            columns={columns}
            refresh={refresh}
        />
    )
}