import { Form, Input, Row, Col } from "antd";
import React, { useState } from "react";
import FormPage from "../../../../../components/pages/FormPage";

const DefModel = {
    code: "",
    icao: "",
    name: "",
    country: "",
    prefix: "",
    airport_id: null,
    isCreate: true,
};

export default () => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={"api/v1/airport"}
            callbackPath={"/master/airport"}
            formValid={false}
            model={model}
            setModel={setModel}
            title={"Airport"}
        >
            <Row gutter={24}>
                <Col className="gutter-row" span={8}>
                    <Form.Item name={"code"} label="IATA Code" rules={[{ required: true }]} >
                        <Input autoFocus className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item name={"icao_code"} label="ICAO Code">
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                    <Form.Item name={"country"} label="Country Code" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>

                <Col className="gutter-row" span={12}>
                    <Form.Item name={"name"} label="Name">
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                    <Form.Item name={"timezone"} label="Time zone" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                    <Form.Item name={"kota"} label="City" rules={[{ required: true }]}>
                        <Input className="input-custom-md" />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    );
}
