import { grey } from '@ant-design/colors';
import { Button, Divider, Form, Input, Typography } from 'antd';
import React, { Fragment, useCallback } from 'react';
import { RiLoginCircleLine, RiUser3Line, RiLockLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/reducer/auth';
import { useEffect } from 'react';
import { loadVersionLast } from '../../redux';
import { AppVersion } from '../../config/appVersion';
import moment from 'moment';
import Config from '../../config/env';

const { Title } = Config

export default ({ apps }) => { // eslint-disable-line
    const dispatch = useDispatch();
    const { versionLastCache: beVersion } = useSelector(state => state.apps);

    const onFinished = useCallback((values) => {
        dispatch(login({ ...values, app: apps }));
    }, []) // eslint-disable-line

    useEffect(() => {
        dispatch(loadVersionLast());
    }, [])

    return (
        <Fragment>
            <div className={`loginBackground login-${apps.toLowerCase()}`} />
            <div style={{ display: 'flex', flexDirection: 'row', width: '100vw', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <Form
                    title={`Login To ${apps}`}
                    layout='vertical'
                    style={{ width: 420, border: `solid 1px ${grey[1]}`, paddingInline: 16, paddingBlock: 24, background: 'white', borderRadius: 10 }}
                    labelAlign='left'
                    onFinish={onFinished}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Typography.Title type='secondary' level={3} style={{ textAlign: 'center' }}>LOGIN | {Title}</Typography.Title>
                    <Divider style={{ margin: '20px 0' }} />
                    <div style={{ paddingInline: 24 }}>
                        <Form.Item name={'user'} label="Username" rules={[{ required: true, message: 'Username Required' }]}>
                            <Input autoFocus placeholder='Username' prefix={<RiUser3Line color={grey[2]} />} />
                        </Form.Item>
                        <Form.Item name={'password'} label="Password" rules={[{ required: true, message: 'Password Required' }]}>
                            <Input.Password placeholder='Password' prefix={<RiLockLine color={grey[2]} />} />
                        </Form.Item>
                        <Button
                            icon={<RiLoginCircleLine style={{ marginInline: 5 }} />}
                            block
                            htmlType="submit"
                            type='primary'
                        >
                            Login
                        </Button>
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                        <span style={{ textAlign: 'center' }}>COPYRIGHT © {moment().format("YYYY")} <a target={'_blank'} href="https://edifly-si.com/">Edifly Solusi Indonesia</a></span>
                        <br />
                        <span style={{ textAlign: 'center' }}>Fe: v{AppVersion} | Be: v{beVersion?.version}</span>
                    </div>
                </Form>
            </div>
        </Fragment>
    )
}