import React from 'react'
import FormReport from "../../../../../../components/pages/FormReport";
import { useFetch } from '../../../../../../hooks/useFetch';

export default () => {

    return (
        <FormReport
            title={'Export Inventory'}
            repType={'daily'}
            url={"/api/v1/report_export"}
            FetchComponent={useFetch}
        />
    )
}