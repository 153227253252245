import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment';

export default function Clock() {
    const timer = useRef(false);
    const [clock, setClock] = useState(moment());
    useEffect(() => {
        if (timer.current) clearInterval(timer.current);
        timer.current = setInterval(() => {
            setClock(moment());
        }, 1000);
        return () => {
            clearInterval(timer.current);
        }
    }, []);

    return (
        <span>{clock.format('dddd, DD MMM YYYY HH:mm:ss')}</span>
    )
}
