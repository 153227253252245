import React from 'react';
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment';
import numeral from 'numeral';
import DetailPage from './detail';

export default () => {
    const columns = [
        {
            selector: 'msg_source',
            name: 'MSG Source',
            wrap: true,
            format: ({ msg_source }) => msg_source?.smi
        },
        {
            selector: 'origin',
            name: 'Routes',
            wrap: true,
            format: ({ origin, dest }) => `${origin.code}-${dest.code}`
        },
        {
            selector: 'awb_number',
            name: 'AWB Number',
            wrap: true,
        },
        {
            selector: 'product_desc',
            name: 'Desc',
            wrap: true,
        },
        {
            selector: 'flight_detail',
            name: 'Flight Number',
            wrap: true,
            format: ({ flight_details }) => flight_details?.map(({ flight_number, flight_date }) => `${flight_number}/${flight_date}`).join(', ')
        },
        {
            selector: 'shipper',
            name: 'Shipper',
            wrap: true,
            format: ({ shipper }) => shipper?.name
        },
        {
            selector: 'consigne',
            name: 'Consignee',
            wrap: true,
            format: ({ consigne }) => consigne?.name
        },
        {
            selector: 'shipment_detail',
            name: 'Weight/Total',
            wrap: true,
            format: ({ shipment_detail }) => numeral(shipment_detail?.kilo).format('0,00') + '/' + shipment_detail?.total
        },
        {
            selector: 'createdAt',
            name: 'Created At',
            wrap: true,
            format: ({ createdAt }) => moment(createdAt).format('DD-MMM-YYYY HH:mm:ss')
        }
    ];

    return (
        <TablePage
            title={"Airwaybill"}
            url="api/v1/awb"
            columns={columns}
            ExpandComponent={DetailPage}
        />
    )
}