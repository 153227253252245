import React from 'react'
import FormReport from "../../../../../../components/pages/inventory/ReportInventory";
import { useFetch } from '../../../../../../hooks/useFetch';

export default () => {

    return (
        <FormReport
            title={'Export Abandon'}
            repType={'abandon'}
            url={"/api/v1/report_export"}
            FetchComponent={useFetch}
        />
    )
}