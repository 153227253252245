import { Form, Input, Row, Col, DatePicker, InputNumber, Tooltip, Tabs } from 'antd';
import React, { Fragment, useState } from 'react';
import FormPage from '../../../../../../components/pages/FormPage';
import moment from 'moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RiFlightTakeoffFill } from "react-icons/ri";
import UploadExcel from './uploadExcel';

const DefModel = {
    flight_number: null,
    flight_date: moment(),
    mawb_number: '',
    hawb_number: '',
    mawb_date: moment(),
    hawb_date: moment(),
    weight: 0,
    pieces: 0
}

export default () => {
    const history = useHistory();
    const [model, setModel] = useState(DefModel);
    const resultModel = { ...model, flight_date: moment(model?.flight_date), mawb_date: moment(model?.mawb_date), hawb_date: moment(model?.hawb_date), mawb_number: model?.awb_number }
    const formatAwbNumber = (value) => {
        let str = value;
        if (str.length > 4 && str.substring(3, 4) !== '-') {
            str = str.substring(0, 3) + '-' + str.substring(3);
        }
        return str;
    }
    return (
        <Fragment>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Form" key="1">
                    <FormPage
                        url={'api/v1/export_awb/awb_export'}
                        formValid={false}
                        model={!!model?.flight_number ? resultModel : model}
                        layout="vertical"
                        setModel={setModel}
                        formSize="default"
                        title={"Acceptance Export"}
                        emptyModel={DefModel}
                        callbackPath={"/tps/export/awb"}
                        onSaveEvent={(model) => {
                            const {
                                _id,
                                flight_date, mawb_date, hawb_date,
                                flight_number, mawb_number, hawb_number,
                                weight, pieces, isCreate
                            } = model;
                            return {
                                _id,
                                flight_date: moment(flight_date).format('DD-MMM-YYYY'),
                                mawb_date: moment(mawb_date).format('YYYYMMDD'),
                                hawb_date: moment(hawb_date).format('YYYYMMDD'),
                                flight_number,
                                mawb_number,
                                hawb_number,
                                weight,
                                pieces,
                                isCreate
                            }
                        }}
                    >
                        <Row gutter={24} style={{ width: '50%' }}>
                            <Col span={12}>
                                <Form.Item
                                    label="Flight Number"
                                    name="flight_number"
                                    rules={[{ required: true, message: 'Flight Number wajib di isi' }]}
                                >
                                    <Input
                                        autoFocus
                                        autoComplete="off"
                                        onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                        addonAfter={<Tooltip placement="topLeft" title="Create Flight">
                                            <RiFlightTakeoffFill style={{ cursor: "pointer" }} onClick={() => history.push({ pathname: '/fr/create' })} />
                                        </Tooltip>}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Flight Date" name={'flight_date'} rules={[{ required: true }]}>
                                    <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="MAWB Number" name={'mawb_number'}
                                    rules={[
                                        {
                                            required: true,
                                            pattern: /^\d{3}-\d+$/,
                                            message: 'MAWB Number harus dalam format 000-0000000',
                                        },
                                    ]}
                                    getValueFromEvent={(event) => formatAwbNumber(event.target.value)}
                                >
                                    <Input autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="MAWB Date" name={'mawb_date'} rules={[{
                                    required: true,
                                    message: "Mawb Date wajib di isi"
                                }]}>
                                    <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="HAWB Number" name={'hawb_number'}
                                >
                                    <Input autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="HAWB Date" name={'hawb_date'} rules={[
                                    { required: true, message: "Hawb Date wajib di isi" }
                                ]}>
                                    <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item label="Pieces" name={'pieces'} rules={[{ required: true, message: "Pieces wajib di isi" }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Weight" name={'weight'} rules={[{ required: true, message: "Weight wajib di isi" }]}>
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </FormPage>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Upload Excel" key="2">
                    <UploadExcel />
                </Tabs.TabPane>
            </Tabs>
        </Fragment >
    )
}