import React, { useState } from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import moment from 'moment';
import {
    RiFileShield2Line
} from 'react-icons/ri';
import Modals from './modal';

export default () => { // eslint-disable-line
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [awb, setAwb] = useState(null);
    const [refresh, setRefresh] = useState(moment().unix());

    const showModal = (row) => {
        setIsModalOpen(true);
        setAwb({
            _id: row?._id,
            mawb_number: row?.mawb_number,
            hawb_number: row?.hawb_number,
        })
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setAwb(null)
    };

    const columns = [
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY')) || '-',
            name: "Tanggal AWB",
            id: 'createdAt'
        },
        {
            selector: (row) => row?.mawb_number,
            name: "Master AWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },

        {
            selector: (row) => row?.no_bc11,
            name: "NO BC11",
            id: 'no_bc11'
        },
        {
            selector: (row) => row?.tgl_bc11,
            name: "TGL BC11",
            id: 'tgl_bc11'
        },
        {
            selector: (row) => row?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "FL Date",
            id: 'flight_date'
        },
        {
            selector: (row) => row?.bruto,
            name: "Weight",
            id: 'bruto'
        },
        {
            selector: (row) => row?.jml_kemas,
            name: "Pieces",
            id: 'jml_kemas'
        },
        {
            name: "Action Verify",
            id: '_id',
            selector: (row) => {
                return (
                    <>
                        {
                            row?._id === undefined ?
                                <RiFileShield2Line size={20} style={{ color: '#cccc', cursor: 'not-allowed' }} /> :
                                <RiFileShield2Line size={20} style={{ color: 'green', cursor: 'pointer' }} onClick={() => showModal(row)} />
                        }
                    </>
                )
            }
        },
    ]
    return (
        <>
            <Modals isModalOpen={isModalOpen} handleCancel={handleCancel} awb={awb} setRefresh={setRefresh} />
            <TablePage
                title={"Import Verify"}
                url="api/v1/verify/import"
                actionPosition={'right'}
                columns={columns}
                refresh={refresh}
            />
        </>
    )
}