import React, { useEffect } from 'react';
import DailyFormReport from './reports/dailyForm';
import MonthlyFormReport from './reports/monthlyForm';
import CONFIG from '../../config/env'

const { Title } = CONFIG;

export default ({ title, url, repType, isDateRange, FetchComponent, onDataChange, printHeader, printFooter,
    onDateChange, onFilterChange }) => {

    useEffect(() => {
        document.title = `${Title} (${title})`;
    }, []);

    return <DailyFormReport
        url={url}
        repType={repType}
        title={title}
        isDateRange={isDateRange}
        FetchComponent={FetchComponent}
        printFooterReport={printFooter}
        printHeaderReport={printHeader}
        onDataChange={onDataChange}
        onDateChange={onDateChange}
        onFilterChange={onFilterChange}
    />
}