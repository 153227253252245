import React from 'react';
import { grey } from '@ant-design/colors';
import { Button, Form, Input, Modal } from 'antd';
import { RiUser3Line, RiLockLine } from 'react-icons/ri'
import { useDispatch } from 'react-redux';
import { PostAPI } from '../../redux';

export default ({ visible, setVisible, handleProcess }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const handleOk = () => {
        form
            .validateFields()
            .then(values => {
                const url = 'api/v1/tps_logs/resend_verify';
                dispatch(PostAPI({ url, data: values })).then(resp => {
                    if (!!resp?.payload) {
                        handleProcess()
                        form.resetFields();
                        setVisible(false);
                    }
                })
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    return (
        <Modal
            title="Verif Admin"
            visible={visible}
            onCancel={() => setVisible(false)}
            onOk={handleOk}
            footer={
                [
                    <Button key="submit" type="primary" onClick={handleOk}>
                        Verify & Send
                    </Button>,
                ]}
            width={"20%"}
        >
            <Form
                title={`Verify`}
                layout='vertical'
                style={{ width: "100%" }}
                labelAlign='left'
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 24,
                }}
                form={form}
            >
                <Form.Item name={'username'} label="Username" rules={[{ required: true, message: 'Username Required' }]}>
                    <Input autoFocus placeholder='Username' prefix={<RiUser3Line color={grey[2]} />} autoComplete="off" />
                </Form.Item>
                <Form.Item name={'password'} label="Password" rules={[{ required: true, message: 'Password Required' }]}>
                    <Input.Password placeholder='Password' prefix={<RiLockLine color={grey[2]} />} autoComplete="off" />
                </Form.Item>
            </Form>
        </Modal >
    )
}