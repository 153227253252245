import React from 'react';
import { useFetch } from '../../../../../../hooks/useFetch';

export default ({ data }) => {
    const { _id } = data;
    const [rows, loading] = useFetch(`api/v1/tps_distribusi/airwaybill/${_id}`);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
            <table style={{ border: '1px solid black' }}>
                <tr style={{ border: '1px solid black' }}>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Awb Number</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Hawb Number</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Pieces</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Weight</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Shipper Name</th>
                    <th style={{ border: '1px solid black', fontSize: 11 }}>Consignee Name</th>
                </tr>
                {rows && Array.isArray(rows) && rows.map((item, idx) => (
                    <>
                        <tr style={{ border: '1px solid black' }}>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.mawb_number}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.hawb_number}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.jml_kemas}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.bruto}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.shp_name}</td>
                            <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.exportir_name}</td>
                        </tr>
                    </>
                ))}
            </table>
        </div>
    )
}