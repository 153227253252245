import { Form, Input, Row, Col, Divider } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../../components/pages/FormPage'
import moment from 'moment';

const DefModel = {
    no_bc11: '',
    no_pos_bc11: '',
    tgl_bc11: ''
}

export default () => {
    const [model, setModel] = useState({ ...DefModel });

    return (
        <FormPage
            url={'api/v1/tps/export_cancel'}
            formValid={false}
            model={model}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Form Distribusi Cancel"}
            emptyModel={DefModel}
            callbackPath={"/tps/distribusi/distribusi_cancel"}
        >
            <Row gutter={16} style={{ width: '50%' }}>
                <Divider>HEADER</Divider>
                <Col span={12}>
                    <Form.Item label="KD DOK" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={6} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="CALL SIGN" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_record_id?.airline_id?.code} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="KD TPS" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.kode_tps} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL TIBA" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={moment(model?.awb_id?.flight_record_id?.flight_date_str).format("DD-MMM-YYYY")} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NM ANGKUT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_record_id?.airline_id?.name} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD GUDANG" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.kode_gudang_ekspor} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO NOVY FLIGHT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="REF NUMBER" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={`${model?.kode_tps}${moment().format('YYMMDD')}`} readOnly />
                    </Form.Item>
                </Col>

                <Divider>DETAIL</Divider>
                <Col span={8}>
                    <Form.Item label="NO BL AWB" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.hawb_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="KD DOK INOUT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.kd_dok_inout} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="NO IJIN TPS" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.no_izin_tps} readOnly />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item label="TGL BL AWB" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={!!model?.hawb_date ? moment(model.hawb_date).format("YYYYMMDD") : null} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="NO DOK INOUT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.no_dok_inout} readOnly />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label="TGL IJIN TPS" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={`${model?.tgl_izin_tps}`} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO MASTER BL AWB" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.mawb_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL DOK INOUT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={moment(model?.tgl_dok_inout).format('DD-MMM-YYYY')} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="TGL MASTER BL AWB" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={!!model?.mawb_date ? moment(model.mawb_date).format('YYYYMMDD') : null} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="WK INOUT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={moment().format('YYYYMMDDHHmmss')} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="ID CONSIGNEE" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.exportir_npwp} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD SAR ANGKUT INOUT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue="4" readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="CONSIGNEE" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.exportir_name} readOnly />
                    </Form.Item>
                </Col>
                {/* <Col span={12}>
                    <Form.Item label="NOPOL" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.awb_id?.flight_number} readOnly />
                    </Form.Item>
                </Col> */}

                <Col span={12}>
                    <Form.Item label="BRUTO" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.awb_id?.weight} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KOLI" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={model?.awb_id?.pieces} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO BC11" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue="" readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="TGL BC11" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue="" readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="PEL_MUAT" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={`${model?.awb_id?.origin?.country}${model?.awb_id?.origin?.code}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="PEL BONGKAR" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={`${model?.awb_id?.dest?.country}${model?.awb_id?.dest?.code}`} readOnly />
                    </Form.Item>
                </Col>

                {/* <Col span={12}>
                    <Form.Item label="GUDANG TUJUAN" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue="" readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="CONT ASAL" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue="" readOnly />
                    </Form.Item>
                </Col> */}
                <Col span={12}>
                    <Form.Item label="KODE KANTOR" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={`${model?.kode_kantor}`} readOnly />
                    </Form.Item>
                </Col>

                {/* <Col span={12}>
                    <Form.Item label="SERI KEMAS" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue="" readOnly />
                    </Form.Item>
                </Col> */}
                <Col span={12}>
                    <Form.Item label="NO DAFTAR PABEAN" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={`${model?.peb_number}`} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="KD KEMAS" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue="BX" readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL DAFTAR PABEAN" rules={[{ required: true }]}>
                        <Input autoComplete="off" defaultValue={`${model?.peb_date}`} readOnly />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage>
    )
}