
import React from 'react'
import { Row, Col, Result } from 'antd';
import Clock from './clock';
import { Helmet } from "react-helmet";
import { useAutogate } from '../../hooks/useAutogate';
import { ClockCircleOutlined } from '@ant-design/icons';

export default () => {
    const { atgData } = useAutogate('importin');

    const screenStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh'
    };

    const headerStyle = {
        textAlign: 'center',
        color: 'white',
        fontSize: '100px',
        fontWeight: 'bold',
        paddingBottom: '2vw',
    };

    const subHeaderStyle = {
        textAlign: 'center',
        color: 'white',
        fontSize: '60px',
        paddingBottom: '4vw',
    };

    const containerStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        width: '80%',
    };

    const cardStyle = {
        borderRadius: '10px',
        backgroundColor: 'white',
        padding: '1vw',
        textAlign: 'center',
        margin: '1vw',
        width: '30%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const titleStyle = {
        fontSize: '25px',
        fontWeight: 'bold',
    };

    const subtitleStyle = {
        fontSize: '22px',
        fontWeight: 'bold',
    };

    return (
        <div>
            <Helmet>
                <title>TPS Online - Gate Import In</title>
            </Helmet>
            <Row type="flex" justify="space-around" align="middle" style={{ height: '10vh', border: '1px solid #cccccc', }}>
                <Col>
                    <img src={"/images/bea_cukai.png"} alt="My Image" width={80} />
                </Col>
                <Col>
                    <div style={{ textAlign: 'center' }}>
                        <h2 style={{ fontSize: 20 }}>GATE IMPORT IN</h2>
                        <span>Auto Gate System | Area Terminal Kargo</span> <br />
                        <Clock />
                    </div>
                </Col>
                <Col>
                    <img src={"/images/logo.png"} alt="My Image" width={100} />
                </Col>
            </Row>

            <Row type="flex" style={{ backgroundColor: "#379237" }}>
                <Col span={24}>
                    {!atgData?.awb ?
                        <div style={{ height: "calc(90vh)", display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                            <Result
                                icon={<ClockCircleOutlined style={{ color: "white" }} />}
                                title={<span style={{ color: "white" }}>Belum Ada Data</span>}
                            />
                        </div>
                        :
                        <>
                            <div style={screenStyle}>
                                <div style={headerStyle} data-aos="fade-up">
                                    {atgData?.hawb || "-"}
                                </div>
                                <div style={subHeaderStyle} data-aos="fade-up">
                                    {atgData?.kd_doc || "-"} <br />
                                    {atgData?.tgl_doc || "-"}
                                </div>
                                <div style={containerStyle}>
                                    <div style={cardStyle} data-aos="zoom-in">
                                        <div style={titleStyle}>NOMOR DAFTAR PABEAN</div>
                                        <div style={subtitleStyle}>{atgData?.no_pabean || "-"}</div>
                                    </div>
                                    <div style={cardStyle} data-aos="zoom-in">
                                        <div style={titleStyle}>NOMOR MAWB</div>
                                        <div style={subtitleStyle}>{atgData?.awb || "-"}</div>
                                    </div>
                                    <div style={cardStyle} data-aos="zoom-in">
                                        <div style={titleStyle}>NAMA CONSIGNEE</div>
                                        <div style={subtitleStyle}>{atgData?.exportir || "-"}</div>
                                    </div>
                                    <div style={cardStyle} data-aos="zoom-in">
                                        <div style={titleStyle}>TANGGAL DAFTAR PABEAN</div>
                                        <div style={subtitleStyle}>{atgData?.tgl_pabean || "-"}</div>
                                    </div>
                                    <div style={cardStyle} data-aos="zoom-in">
                                        <div style={titleStyle}>JUMLAH PK (PCS)</div>
                                        <div style={subtitleStyle}>{atgData?.pieces || "-"}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </Col>
            </Row>
        </div >
    )
}
