
import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Avatar } from 'antd';
import { GetAPI } from '../../redux';
import { useDispatch } from 'react-redux';
import Clock from './clock';
import { Helmet } from "react-helmet";
const { Text } = Typography;

export default () => {
    const dispatch = useDispatch()
    const [autogateData, setAutogate] = useState([])
    const [currentIndex, setCurrentIndex] = useState(0);
    // const [autogateSelected, setAutogateSelected] = useState({})

    const fectAutogateData = () => {
        dispatch(GetAPI({ url: 'autogate/distribusiout' }))
            .then(resp => {
                setAutogate(resp.payload.data.data)
                // setAutogateSelected(resp.payload.data.data[0])
            })
    }

    useEffect(() => {
        fectAutogateData()
        const interval = setInterval(() => {
            fectAutogateData()
        }, 10000);
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        if (autogateData.length > 0) {
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % autogateData.length);
            }, 3000);

            return () => clearInterval(interval);
        }
    }, [autogateData]);

    return (
        <div>
            <Helmet>
                <title>TPS Online - Gate Distribusi Out</title>
            </Helmet>
            <Row type="flex" justify="space-around" align="middle">
                <Col>
                    <img src={"/images/bea_cukai.png"} alt="My Image" width={80} />
                </Col>
                <Col>
                    <div style={{ textAlign: 'center' }}>
                        <h2 style={{ fontSize: 30 }}>GATE DISTRIBUSI OUT</h2>
                        <span>Auto Gate System | Area Terminal Kargo</span> <br />
                        <Clock />
                    </div>
                </Col>
                <Col>
                    <img src={"/images/logo.png"} alt="My Image" width={100} />
                </Col>
            </Row>

            <Row type="flex">
                <Col span={16}>
                    <div style={{ position: 'absolute', width: '100%' }}>
                        {autogateData.length === 0 ?
                            <span></span> :
                            <Row style={{ marginTop: 20 }}>
                                <Col span={12} style={{ textAlign: 'left' }}>
                                    {/* <Text style={{ marginLeft: 10, fontSize: 20, color: "white", marginLeft: 20 }}>{autogateData[currentIndex]?.mawb_number} / {autogateData[currentIndex]?.hawb_number}</Text> */}
                                </Col>
                                <Col span={12} style={{ textAlign: 'right' }}>
                                    <Avatar style={{ marginRight: 30, backgroundColor: 'white', padding: 15 }} src={`/images/airline_logo/${autogateData[currentIndex]?.flight_record_id?.airline_id?.code?.toLowerCase() || "none"}.png`} size={80} />
                                </Col>
                            </Row>
                        }
                    </div>
                    <div style={{ height: "calc(100vh - 106px)", overflow: 'auto', backgroundColor: '#096dd9', display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                        {autogateData.length === 0 ?
                            <h1 style={{ color: 'white' }}>No Data Exists</h1> :
                            <div style={{ display: 'block', textAlign: 'center', color: 'white' }}>
                                {/* <h1 style={{ color: 'white', fontSize: 50 }}>{autogateData[currentIndex]?.no_dok_inout}</h1>
                                <h1 style={{ color: 'white' }}>{autogateData[currentIndex]?.peb_number} / {moment(autogateData[currentIndex]?.peb_date).format("DD-MMM-YYYY")}</h1>
                                <h1 style={{ color: 'white' }}>{moment(autogateData[currentIndex]?.tgl_dok_inout).format("DD-MMM-YYYY")}</h1> */}
                                <h1 style={{ color: 'white', fontSize: 50 }}>{autogateData[currentIndex]?.exportir_name}</h1>
                                <h1 style={{ color: 'white', fontSize: 80 }}>{autogateData[currentIndex]?.mawb_number}/{autogateData[currentIndex]?.hawb_number}</h1>
                            </div>
                        }
                    </div>
                </Col>
                <Col span={8}>
                    <div style={{ height: "calc(100vh - 106px)", backgroundColor: '#ECECEC', overflow: 'auto' }}>
                        {autogateData.length === 0 ?
                            <p></p> :
                            <>
                                {autogateData.map((item) => (
                                    <Row style={{
                                        borderBottom: '1px solid #7B8FA1', padding: '16px 0', backgroundColor: item?.mawb_number === autogateData[currentIndex]?.mawb_number && item?.hawb_number === autogateData[currentIndex]?.hawb_number ? '#BDCDD6' : '',
                                        // cursor: 'pointer' 
                                    }}
                                    // onClick={() => setAutogateSelected(item)}
                                    >
                                        <Col span={12} style={{ textAlign: 'left' }}>
                                            <Text style={{ marginLeft: 10 }}>{item?.mawb_number}/{item?.hawb_number}</Text>
                                        </Col>
                                        <Col span={12} style={{ textAlign: 'right' }}>
                                            <Text style={{ marginRight: 10 }}>{item?.exportir_name}</Text>
                                        </Col>
                                    </Row>
                                ))}
                            </>
                        }
                    </div>
                </Col>
            </Row>
        </div >
    )
}