import React from 'react'
import TablePage from '../../../../../../components/pages/TablePage'
import { RiArrowRightCircleFill } from 'react-icons/ri'
import moment from 'moment'

export default ({ setModel, model }) => { // eslint-disable-line
    const columns = [
        {
            selector: (row) => row?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.mawb_number,
            name: "Master AWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => <span>{row?.origin?.code} - {row?.dest?.code}</span>,
            name: "Route",
            id: 'state'
        },
        {
            selector: (row) => row?.flight_date,
            name: "FL Date",
            id: 'flight_date_str'
        },
        {
            selector: (row) => {
                const { _id, flight_record_id, no_bc11, tgl_bc11, seri_kemas, jml_kemas, mawb_number, mawb_date, kode_tps, kode_gudang_impor } = row;
                return (
                    <RiArrowRightCircleFill size={20} style={{ cursor: "pointer" }}
                        onClick={() => {
                            setModel({
                                ...model,
                                _id,
                                call_sign: flight_record_id?.carrier,
                                nm_angkut: flight_record_id?.airline_id?.name,
                                no_voy_flight: flight_record_id?.flight_number,
                                no_bc11,
                                tgl_bc11: moment(tgl_bc11),
                                jns_kemas: seri_kemas,
                                jml_kemas,
                                no_bl_awb: mawb_number,
                                tgl_bl_awb: moment(mawb_date),
                                kd_tps_asal: kode_tps,
                                gudang_asal: kode_gudang_impor,
                            })
                        }}
                    />
                )
            },
            name: "Action",
            id: 'reff_number'
        },
    ]
    return (
        <TablePage
            title={"Import Out"}
            url="api/v1/tps_import/import_out"
            actionPosition={'right'}
            columns={columns}
        />
    )
}