import { Form, Input, Row, Col, Divider, DatePicker, Badge } from 'antd';
import React, { useState } from 'react'
import FormPage from '../../../../../../components/pages/FormPage2'
import moment from 'moment';
import numeral from 'numeral';

const DefModel = {
    wk_inout: moment().format('YYYYMMDDHHmmss'),
    tgl_tiba: moment().format('YYYYMMDD'),
    mawb_date: moment().format('YYYYMMDD'),
    hawb_date: moment().format('YYYYMMDD'),
    tgl_bc11: moment().format('YYYYMMDD'),
    cont_asal: ''
}

export default () => {
    const [model, setModel] = useState({ ...DefModel });

    const handleDateChange = (name, date, dateString) => {
        const formattedDate = moment(date).format('YYYYMMDD');
        setModel((prevState) => ({ ...prevState, [name]: formattedDate }));
    };

    return (
        <FormPage
            url={'api/v1/tps_distribusi/distribusi_in'}
            formValid={false}
            model={model}
            layout="vertical"
            setModel={setModel}
            formSize="default"
            title={"Form Distribusi In"}
            emptyModel={DefModel}
            callbackPath={"/tps/distribusi/distribusi_in"}
            onSaveEvent={(model) => {
                const { kode_kemas, ...rest } = model;
                return {
                    ...rest,
                    kode_kemas: kode_kemas.toUpperCase()
                }
            }}
        >
            <Row gutter={16} style={{ width: '50%' }}>
                <Divider>HEADER</Divider>
                <Col span={12}>
                    <Form.Item label="KD DOK">
                        <Input autoComplete="off" defaultValue={2} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="CALL SIGN">
                        <Input autoComplete="off" defaultValue={model?.flight_record_id?.airline_id?.code} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="KD TPS" name={"kode_tps_distribusi"}>
                        <Input autoComplete="off" defaultValue={model?.kode_tps_distribusi} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL KEBERANGKATAN
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={moment(model?.tgl_tiba)}
                            onChange={(date, dateString) => handleDateChange("tgl_tiba", date, dateString)}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NM ANGKUT">
                        <Input autoComplete="off" defaultValue={model?.flight_record_id?.airline_id?.name} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KD GUDANG" name={"kode_gudang_distribusi"}>
                        <Input autoComplete="off" defaultValue={model?.kode_gudang_distribusi} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO NOVY FLIGHT">
                        <Input autoComplete="off" defaultValue={model?.flight_record_id?.flight_number} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="REF NUMBER">
                        <Input autoComplete="off" defaultValue={`${model?.kode_tps}${moment().format('YYMMDD')}${numeral(model?.no_urut_distribusi + 1).format('000000')}`} readOnly />
                    </Form.Item>
                </Col>

                <Divider>DETAIL</Divider>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            NO HAWB
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }
                        name={"hawb_number"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.hawb_number} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={
                            <>
                                TGL HAWB
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={model?.hawb_date !== null ? moment(model?.hawb_date) : moment()}
                            onChange={(date, dateString) => handleDateChange("hawb_date", date, dateString)}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            ID CONSIGNEE HAWB
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={"exportir_npwp"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input maxLength={15} autoComplete="off" defaultValue={model?.exportir_npwp} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label={
                        <>
                            CONSIGNEE HAWB
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={"exportir_name"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input autoComplete="off" value={model?.exportir_name} onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            NO MASTER AWB
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    } name={'mawb_number'}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.mawb_number} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={
                            <>
                                TGL MASTER AWB
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={model?.mawb_date !== null ? moment(model?.mawb_date) : moment()}
                            onChange={(date, dateString) => handleDateChange("mawb_date", date, dateString)}
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label=" ID CONSIGNEE MASTER"
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input maxLength={15} autoComplete="off" defaultValue={model?.exportir_npwp_master} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="CONSIGNEE MASTER"
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input autoComplete="off" value={model?.exportir_name_master} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label="NO IJIN TPS" name={'no_izin_tps_distribusi'}>
                        <Input autoComplete="off" defaultValue={model?.no_izin_tps_distribusi} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="TGL IJIN TPS">
                        <Input autoComplete="off" defaultValue={`${moment(model?.tgl_izin_tps).format('DD-MMM-YYYY')}`} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item label={
                        <>
                            TGL DOK INOUT
                            <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                        </>
                    }>
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={model?.tgl_dok_inout !== null ? moment(model?.tgl_dok_inout) : moment()}
                            onChange={(date, dateString) => handleDateChange("tgl_dok_inout", date, dateString)}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="WK INOUT" name={"wk_inout"}>
                        <Input autoComplete="off" defaultValue={moment().format('YYYYMMDDHHmmss')} readOnly />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label={
                            <>
                                KOLI (PCS)
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                        name={"jml_kemas"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.jml_kemas} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={
                            <>
                                BRUTO (KG)
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                        name={"bruto"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue={model?.bruto} />
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        label={
                            <>
                                NO BC11
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                        name={"no_bc11"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue="" />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <>
                                TGL BC11
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                    >
                        <DatePicker
                            style={{ width: '100%' }}
                            format="DD-MMM-YYYY"
                            value={model?.tgl_bc11 !== null ? moment(model?.tgl_bc11) : moment()}
                            onChange={(date, dateString) => handleDateChange("tgl_bc11", date, dateString)}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item
                        label={
                            <>
                                NO POS BC11
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                        name={"no_pos_bc11"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" defaultValue="" />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        initialValue={`${model?.origin?.country}${model?.origin?.code}`}
                        label={
                            <>
                                PEL MUAT
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={"muat"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        initialValue={`${model?.dest?.country}${model?.dest?.code}`}
                        label={
                            <>
                                PEL BONGKAR
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={"bongkar"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="KODE KANTOR" name={"kode_kantor"}>
                        <Input autoComplete="off" defaultValue={`${model?.kode_kantor}`} readOnly />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        initialValue="Y"
                        label={
                            <>
                                SERI KEMAS
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        }
                        name={"seri_kemas"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        initialValue="PK"
                        label={
                            <>
                                KD KEMAS
                                <Badge count="edit" style={{ backgroundColor: '#52c41a', marginLeft: 8 }} />
                            </>
                        } name={"kode_kemas"}
                        rules={[
                            {
                                required: true,
                            },
                        ]}>
                        <Input onInput={e => e.target.value = e.target.value.toUpperCase()} autoComplete="off" />
                    </Form.Item>
                </Col>
            </Row>
        </FormPage >
    )
}