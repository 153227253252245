import React from 'react';
import { useFetch } from '../../../../../../hooks/useFetch';
import PrintBrcImp from './printBarcode';
import reduceSum from 'reduce-sum';
import { RiPrinterFill } from "react-icons/ri";
import moment from 'moment';

function DetailHawb({ record }) {
    const [rows, loading] = useFetch(`api/v1/tps_import/airwaybill/${record?._id}`);

    const Ttls = rows || [];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
            <table style={{ border: '1px solid #cccccc' }}>
                <tr style={{ border: '1px solid #cccccc' }}>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>No</th>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>Awb Number</th>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>Hawb Number</th>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>NO BC11</th>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>TGL BC11</th>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>Pieces</th>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>Weight</th>
                    <th style={{ border: '1px solid #cccccc', fontSize: 11, textAlign: "center" }}>Print Barcode</th>
                </tr>
                {rows && Array.isArray(rows) && rows.map((item, idx) => (
                    <>
                        <tr style={{ border: '1px solid #cccccc' }}>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{idx + 1}</td>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{item?.mawb_number}</td>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{item?.hawb_number}</td>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{item?.no_bc11}</td>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{moment(item?.tgl_bc11).format('DD-MMM-YYYY')}</td>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{item?.jml_kemas}</td>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{item?.bruto}</td>
                            <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center", width: 25 }}>
                                <PrintBrcImp
                                    data={item}
                                    title="Print Barcode"
                                    icon={<RiPrinterFill />}
                                    iconBtnProps={{
                                        size: "small",
                                    }}
                                />
                            </td>

                        </tr>
                    </>
                ))}

                <tr style={{ border: '1px solid #cccccc' }}>
                    <td colSpan={4} style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>TOTAL</td>
                    <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{reduceSum(Ttls, 'jml_kemas')}</td>
                    <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center" }}>{reduceSum(Ttls, 'bruto')}</td>
                    <td style={{ border: '1px solid #cccccc', fontSize: 10, textAlign: "center", width: 25 }}> </td>
                </tr>
            </table>
        </div>
    )
}

export default DetailHawb