import React from "react";
import { Modal } from "antd";

const ModalChangePass = ({ isOpen, title, children }) => {
  return (
    <Modal
      closable={false}
      footer={false}
      centered
      title={title}
      visible={isOpen}
    >
      {children}
    </Modal>
  );
};

export default ModalChangePass;
