import React, { useEffect } from 'react';
import DailyFormReport from './reports/dailyForm';
import MonthlyFormReport from './reports/monthlyForm';
import CONFIG from '../../config/env'

const { Title } = CONFIG;

export default ({ title, url, repType, repUrl, isDateRange, FetchComponent, onDataChange, printHeader, printFooter,
    onDateChange, onFilterChange }) => {

    useEffect(() => {
        document.title = `${Title} (${title})`;
    }, []);

    return repType === 'daily' ? (
        <DailyFormReport
            url={url}
            repType={repUrl || repType}
            title={title}
            isDateRange={isDateRange}
            FetchComponent={FetchComponent}
            printFooterReport={printFooter}
            printHeaderReport={printHeader}
            onDataChange={onDataChange}
            onDateChange={onDateChange}
            onFilterChange={onFilterChange}
        />
    ) : (
        <MonthlyFormReport
            url={url}
            repType={repUrl || repType}
            title={title}
            isDateRange={isDateRange}
            FetchComponent={FetchComponent}
            printFooterReport={printFooter}
            printHeaderReport={printHeader}
            onDataChange={onDataChange}
            onDateChange={onDateChange}
            onFilterChange={onFilterChange}
        />
    )
}