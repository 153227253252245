import React from 'react'
import { Avatar, Dropdown, Typography, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { logout } from '../../redux/reducer/auth';

export const UserProfile = ({ username, level, isColapsed }) => {
    const dispatch = useDispatch();

    const OverlayMenu = () => {
        return <Menu items={[
            {
                label: <Link to={'/profile'}>Profile</Link>,
                key: 'Profile'
            },
            {
                label: <Link onClick={() => dispatch(logout())} >Logout</Link>,
                key: 'Logout'
            },
        ]} />
    }
    return (
        <div className='user-profile-wrapper' style={{ marginRight: isColapsed ? 128 : 300 }}>
            <div className='user-profile-text-wrapper'>
                <Typography.Text strong type='success' className='user-profile-text'>
                    {username}
                </Typography.Text>
                <Typography.Text type='success' className='user-profile-text user-profile-status'>
                    TPS {level}
                </Typography.Text>
            </div>
            <Dropdown placement='bottomLeft' overlay={OverlayMenu} trigger={['click']}>
                <Avatar size={'large'} icon={<UserOutlined />} />
            </Dropdown>
        </div>
    )
}