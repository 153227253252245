import React from 'react';
import TablePage from '../../../../../components/pages/TablePage';
import { levelToRole } from '../../../../../redux/reducer/levelConvert';

export default () => {
    const columns = [
        {
            selector: (row) => row?.name,
            name: "name",
            id: 'name'
        },
        {
            selector: (row) => row?.username,
            name: "username",
            id: 'username'
        },
        {
            selector: 'level',
            name: 'Role',
            wrap: true,
            format: ({ level }) => levelToRole(level)
        },
    ]
    return (
        <TablePage
            title={"Master Users"}
            url="api/v1/users"
            actionPosition={'first'}
            createPath="/master/users/create"
            editPath={"/master/users/edit"}
            columns={columns}
        />
    )
}