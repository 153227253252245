import React from 'react';
import XMLViewer from 'react-xml-viewer';

export default ({ data }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
            <p variant="h6">Request</p>
            <div style={{ display: 'flex', padding: 16, flexDirection: 'column', width: '80vw', overflow: 'scroll', border: 'solid 1px #efefef', marginBlock: 16 }}>
                <XMLViewer xml={data?.request} />
            </div>
            <p variant="h6">Response</p>
            <div style={{ display: 'flex', padding: 16, flexDirection: 'column', width: '80vw', overflow: 'scroll', border: 'solid 1px #efefef', marginBlock: 16 }}>
                <XMLViewer xml={data?.response} />
            </div>
        </div>
    )
}