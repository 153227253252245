export default function useSpeech() {
    const splib = window.speechSynthesis;
    const voicer = (voiceName) => {
        const voices = splib.getVoices();
        const selectedVoice = voices.find(voice => voice.name === voiceName);

        if (selectedVoice) {
            return selectedVoice;
        } else {
            return null;
        }
    };

    const onCancel = () => splib.cancel();

    const onSpeak = (text) => {
        const utterThis = new SpeechSynthesisUtterance(text);
        const selectedVoice = voicer('Microsoft Ardi Online (Natural) - Indonesian (Indonesia)');
        utterThis.voice = selectedVoice;
        splib.speak(utterThis);
    };

    return {
        onSpeak,
        onCancel
    };
}
