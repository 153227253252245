import React from 'react'
import TablePage from '../../../../../components/pages/TablePage'
import moment from 'moment'
export default () => {
    const columns = [
        {
            selector: (row) => row?.airline_id?.name,
            name: "Airline",
            id: 'airline_id'
        },
        {
            selector: (row) => row?.flight_number,
            name: "Flight Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date_str,
            name: "Flight Date",
            id: 'flight_date_str'
        },
        {
            selector: (row) => row?.acreg,
            name: "ACReg",
            id: 'acreg'
        },
        {
            selector: (row) => row?.origin?.code,
            name: "Origin",
            id: 'origin'
        },
        {
            selector: (row) => row?.dest?.code,
            name: "Dest",
            id: 'dest'
        },
        {
            selector: (row) => row?.localSta,
            name: "STA",
            id: 'localSta'
        },
        {
            selector: (row) => row?.localEta,
            name: "ETA",
            id: 'localEta'
        },
        {
            selector: (row) => row?.localAta,
            name: "ATA",
            id: 'localAta'
        },
        {
            selector: (row) => row?.status,
            name: "Status",
            id: 'status'
        },
        {
            selector: (row) => (row?.createdAt && moment(row.createdAt).format('DD-MMM-YYYY HH:mm')) || '-',
            name: "CreatedAt",
            id: 'created_at'
        },
    ]
    return (
        <TablePage
            title={"Arrival Flight Record"}
            url="api/v1/flight_record/arrival"
            actionPosition={'first'}
            columns={columns}
            createPath="/fr/create"
        />
    )
}