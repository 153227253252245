

export const parseXmlToFormData = (xmlData, setFormData) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlData, 'text/xml');

  // Parsing HEADER data
  const header = xmlDoc.querySelector('HEADER');
  const headerData = {
    KD_DOK: header.querySelector('KD_DOK')?.textContent || '',
    KD_TPS: header.querySelector('KD_TPS')?.textContent || '',
    NM_ANGKUT: header.querySelector('NM_ANGKUT')?.textContent || '',
    NO_VOY_FLIGHT: header.querySelector('NO_VOY_FLIGHT')?.textContent || '',
    CALL_SIGN: header.querySelector('CALL_SIGN')?.textContent || '',
    TGL_TIBA: header.querySelector('TGL_TIBA')?.textContent || '',
    KD_GUDANG: header.querySelector('KD_GUDANG')?.textContent || '',
    REF_NUMBER: header.querySelector('REF_NUMBER')?.textContent || '',
  };

  // Parsing KMS data
  const kms = xmlDoc.querySelector('KMS');
  const kmsData = {
    NO_BL_AWB: kms.querySelector('NO_BL_AWB')?.textContent || '',
    TGL_BL_AWB: kms.querySelector('TGL_BL_AWB')?.textContent || '',
    NO_MASTER_BL_AWB: kms.querySelector('NO_MASTER_BL_AWB')?.textContent || '',
    TGL_MASTER_BL_AWB: kms.querySelector('TGL_MASTER_BL_AWB')?.textContent || '',
    ID_CONSIGNEE: kms.querySelector('ID_CONSIGNEE')?.textContent || '',
    CONSIGNEE: kms.querySelector('CONSIGNEE')?.textContent || '',
    BRUTO: kms.querySelector('BRUTO')?.textContent || '',
    NO_BC11: kms.querySelector('NO_BC11')?.textContent || '',
    TGL_BC11: kms.querySelector('TGL_BC11')?.textContent || '',
    NO_POS_BC11: kms.querySelector('NO_POS_BC11')?.textContent || '',
    CONT_ASAL: kms.querySelector('CONT_ASAL')?.textContent || '',
    SERI_KEMAS: kms.querySelector('SERI_KEMAS')?.textContent || '',
    KD_KEMAS: kms.querySelector('KD_KEMAS')?.textContent || '',
    JML_KEMAS: kms.querySelector('JML_KEMAS')?.textContent || '',
    KD_TIMBUN: kms.querySelector('KD_TIMBUN')?.textContent || '',
    KD_DOK_INOUT: kms.querySelector('KD_DOK_INOUT')?.textContent || '',
    NO_DOK_INOUT: kms.querySelector('NO_DOK_INOUT')?.textContent || '',
    TGL_DOK_INOUT: kms.querySelector('TGL_DOK_INOUT')?.textContent || '',
    WK_INOUT: kms.querySelector('WK_INOUT')?.textContent || '',
    KD_SAR_ANGKUT_INOUT: kms.querySelector('KD_SAR_ANGKUT_INOUT')?.textContent || '',
    NO_POL: kms.querySelector('NO_POL')?.textContent || '',
    PEL_MUAT: kms.querySelector('PEL_MUAT')?.textContent || '',
    PEL_TRANSIT: kms.querySelector('PEL_TRANSIT')?.textContent || '',
    PEL_BONGKAR: kms.querySelector('PEL_BONGKAR')?.textContent || '',
    GUDANG_TUJUAN: kms.querySelector('GUDANG_TUJUAN')?.textContent || '',
    KODE_KANTOR: kms.querySelector('KODE_KANTOR')?.textContent || '',
    NO_DAFTAR_PABEAN: kms.querySelector('NO_DAFTAR_PABEAN')?.textContent || '',
    TGL_DAFTAR_PABEAN: kms.querySelector('TGL_DAFTAR_PABEAN')?.textContent || '',
    NO_SEGEL_BC: kms.querySelector('NO_SEGEL_BC')?.textContent || '',
    TGL_SEGEL_BC: kms.querySelector('TGL_SEGEL_BC')?.textContent || '',
    NO_IJIN_TPS: kms.querySelector('NO_IJIN_TPS')?.textContent || '',
    TGL_IJIN_TPS: kms.querySelector('TGL_IJIN_TPS')?.textContent || ''
  };

  setFormData({
    ...headerData,
    ...kmsData,
  });
};

export const parseJsonToXml = (jsonData) => {
  const {
    KD_DOK, KD_TPS, NM_ANGKUT, NO_VOY_FLIGHT, CALL_SIGN, TGL_TIBA, KD_GUDANG, REF_NUMBER,
    NO_BL_AWB, TGL_BL_AWB, NO_MASTER_BL_AWB, TGL_MASTER_BL_AWB, ID_CONSIGNEE, CONSIGNEE, BRUTO,
    NO_BC11, TGL_BC11, NO_POS_BC11, CONT_ASAL, SERI_KEMAS,
    KD_KEMAS, JML_KEMAS, KD_TIMBUN, KD_DOK_INOUT, NO_DOK_INOUT, TGL_DOK_INOUT, WK_INOUT,
    KD_SAR_ANGKUT_INOUT, NO_POL,
    PEL_MUAT, PEL_TRANSIT, PEL_BONGKAR, GUDANG_TUJUAN, KODE_KANTOR, NO_DAFTAR_PABEAN,
    TGL_DAFTAR_PABEAN, NO_SEGEL_BC, TGL_SEGEL_BC, NO_IJIN_TPS, TGL_IJIN_TPS
  } = jsonData;

  const xmlString = `
      <DOCUMENT xmlns="cocokms.xsd">
        <COCOKMS>
          <HEADER>
            <KD_DOK>${KD_DOK || ''}</KD_DOK>
            <KD_TPS>${KD_TPS || ''}</KD_TPS>
            <NM_ANGKUT>${NM_ANGKUT || ''}</NM_ANGKUT>
            <NO_VOY_FLIGHT>${NO_VOY_FLIGHT || ''}</NO_VOY_FLIGHT>
            <CALL_SIGN>${CALL_SIGN || ''}</CALL_SIGN>
            <TGL_TIBA>${TGL_TIBA || ''}</TGL_TIBA>
            <KD_GUDANG>${KD_GUDANG || ''}</KD_GUDANG>
            <REF_NUMBER>${REF_NUMBER || ''}</REF_NUMBER>
          </HEADER>
          <DETIL>
            <KMS>
              <NO_BL_AWB>${NO_BL_AWB || ''}</NO_BL_AWB>
              <TGL_BL_AWB>${TGL_BL_AWB || ''}</TGL_BL_AWB>
              <NO_MASTER_BL_AWB>${NO_MASTER_BL_AWB || ''}</NO_MASTER_BL_AWB>
              <TGL_MASTER_BL_AWB>${TGL_MASTER_BL_AWB || ''}</TGL_MASTER_BL_AWB>
              <ID_CONSIGNEE>${ID_CONSIGNEE || ''}</ID_CONSIGNEE>
              <CONSIGNEE>${CONSIGNEE || ''}</CONSIGNEE>
              <BRUTO>${BRUTO || ''}</BRUTO>
              <NO_BC11>${NO_BC11 || ''}</NO_BC11>
              <TGL_BC11>${TGL_BC11 || ''}</TGL_BC11>
              <NO_POS_BC11>${NO_POS_BC11 || ''}</NO_POS_BC11>
              <CONT_ASAL>${CONT_ASAL || ''}</CONT_ASAL>
              <SERI_KEMAS>${SERI_KEMAS || ''}</SERI_KEMAS>
              <KD_KEMAS>${KD_KEMAS || ''}</KD_KEMAS>
              <JML_KEMAS>${JML_KEMAS || ''}</JML_KEMAS>
              <KD_TIMBUN>${KD_TIMBUN || ''}</KD_TIMBUN>
              <KD_DOK_INOUT>${KD_DOK_INOUT || ''}</KD_DOK_INOUT>
              <NO_DOK_INOUT>${NO_DOK_INOUT || ''}</NO_DOK_INOUT>
              <TGL_DOK_INOUT>${TGL_DOK_INOUT || ''}</TGL_DOK_INOUT>
              <WK_INOUT>${WK_INOUT || ''}</WK_INOUT>
              <KD_SAR_ANGKUT_INOUT>${KD_SAR_ANGKUT_INOUT || ''}</KD_SAR_ANGKUT_INOUT>
              <NO_POL>${NO_POL || ''}</NO_POL>
              <PEL_MUAT>${PEL_MUAT || ''}</PEL_MUAT>
              <PEL_TRANSIT>${PEL_TRANSIT || ''}</PEL_TRANSIT>
              <PEL_BONGKAR>${PEL_BONGKAR || ''}</PEL_BONGKAR>
              <GUDANG_TUJUAN>${GUDANG_TUJUAN || ''}</GUDANG_TUJUAN>
              <KODE_KANTOR>${KODE_KANTOR || ''}</KODE_KANTOR>
              <NO_DAFTAR_PABEAN>${NO_DAFTAR_PABEAN || ''}</NO_DAFTAR_PABEAN>
              <TGL_DAFTAR_PABEAN>${TGL_DAFTAR_PABEAN || ''}</TGL_DAFTAR_PABEAN>
              <NO_SEGEL_BC>${NO_SEGEL_BC || ''}</NO_SEGEL_BC>
              <TGL_SEGEL_BC>${TGL_SEGEL_BC || ''}</TGL_SEGEL_BC>
              <NO_IJIN_TPS>${NO_IJIN_TPS || ''}</NO_IJIN_TPS>
              <TGL_IJIN_TPS>${TGL_IJIN_TPS || ''}</TGL_IJIN_TPS>
            </KMS>
          </DETIL>
        </COCOKMS>
      </DOCUMENT>
    `;
  return xmlString.replace(/\s+/g, ' ').trim();
};