import { Form, Input } from 'antd';
import React, { useMemo, useState } from 'react'
import FormPage from '../../../../../components/pages/FormPage'

const DefModel = {
    code: '',
    icao: '',
    name: '',
    country: '',
    prefix: '',
    idModule: '',
    userModule: '',
    npwp: '',
    urutAju: '',
    airport_id: null
}

export default () => {
    const [model, setModel] = useState(DefModel);
    return (
        <FormPage
            url={'api/v1/airlines'}
            formValid={false}
            model={model}
            setModel={setModel}
            wrapperCol={{ span: 8 }}
            labelCol={{ span: 3 }}
            title={"Airline"}
            emptyModel={DefModel}
            callbackPath={"/master/airline"}
        >
            <Form.Item name={'code'} label="IATA Code" rules={[{ required: true }]} >
                <Input autoFocus />
            </Form.Item>
            <Form.Item name={'icao'} label="ICAO Code" >
                <Input />
            </Form.Item>
            <Form.Item name={'name'} label="Name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'prefix'} label="Prefix">
                <Input />
            </Form.Item>
            <Form.Item name={'country'} label="Country" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'idModule'} label="Id Module" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'userModule'} label="User Module" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'npwp'} label="npwp" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={'urutAju'} label="Urut Aju" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
        </FormPage>
    )
}