import { Input, Button, Space, Descriptions, Tag, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { useDispatch } from 'react-redux'
import { PostAPI, GetAPI } from '../../../../../../redux'
import { CloseCircleOutlined, SwapOutlined, RollbackOutlined } from '@ant-design/icons'
import { useHistory, useLocation } from 'react-router-dom'

const DefModel = {
    no_bc11: '',
    no_pos_bc11: '',
    tgl_bc11: moment().format('YYYYMMDD'),
}

export default () => { // eslint-disable-line
    const [model, setModel] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation()

    const findExportList = (id) => {
        dispatch(GetAPI({ url: `api/v1/tps/export_list/${id}` }))
            .then(resp => {
                const result = resp.payload?.data;
                if (result?.export_out_params?.tgl_bc11 === undefined) {
                    setModel({ ...result, ...DefModel })
                } else {
                    setModel({ ...result, no_bc11: result?.export_out_params?.no_bc11, no_pos_bc11: result?.export_out_params?.no_pos_bc11, tgl_bc11: result?.export_out_params?.tgl_bc11 })
                }
            })
    }

    const handleSend = (type) => {
        dispatch(PostAPI({ url: `api/v1/tps/${type}`, data: model }))
            .then(resp => {
                findExportList(location.state._id)
            })
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setModel((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleDateChange = (date, dateString) => {
        const formattedDate = moment(date).format('YYYYMMDD');
        setModel((prevState) => ({ ...prevState, tgl_bc11: formattedDate }));
    };

    useEffect(() => {
        findExportList(location.state._id)
    }, []) // eslint-disable-line

    return (
        <>
            <Descriptions title="Header">
                <Descriptions.Item label="REFF NUMBER">{model?.reff_number}</Descriptions.Item>
                <Descriptions.Item label="CALL SIGN">{model?.awb_id?.flight_record_id?.airline_id?.code}</Descriptions.Item>
                <Descriptions.Item label="KD TPS">{model?.kode_tps}</Descriptions.Item>
                <Descriptions.Item label="TGL TIBA">{moment(model?.awb_id?.flight_record_id?.flight_date_str).format("DD-MMM-YYYY")}</Descriptions.Item>
                <Descriptions.Item label="NM ANGKUT">{model?.awb_id?.flight_record_id?.airline_id?.name}</Descriptions.Item>
                <Descriptions.Item label="KD GUDANG">{model?.kode_gudang_ekspor}</Descriptions.Item>
                <Descriptions.Item label="NO NOVY FLIGHT">{model?.awb_id?.flight_number}</Descriptions.Item>
                <Descriptions.Item label="REF NUMBER">{`${model?.kode_tps}${moment().format('YYMMDD')}`}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Document">
                <Descriptions.Item label="No NPE">{model?.no_dok_inout}</Descriptions.Item>
                <Descriptions.Item label="Tgl NPE">{model?.tgl_dok_inout}</Descriptions.Item>
                <Descriptions.Item label="Nama Eks">{model?.exportir_name}</Descriptions.Item>
                <Descriptions.Item label="NPWP Eks">{model?.exportir_npwp}</Descriptions.Item>
            </Descriptions>

            <Descriptions title="Detail">
                <Descriptions.Item label="NO BL AWB">{model?.hawb_number}</Descriptions.Item>
                <Descriptions.Item label="KD DOK INOUT">{model?.kd_dok_inout}</Descriptions.Item>
                <Descriptions.Item label="NO IJIN TPS">{model?.no_izin_tps}</Descriptions.Item>
                <Descriptions.Item label="TGL BL AWB">{!!model?.hawb_date ? moment(model.hawb_date).format("YYYYMMDD") : null}</Descriptions.Item>
                <Descriptions.Item label="NO DOK INOUT">{model?.no_dok_inout}</Descriptions.Item>
                <Descriptions.Item label="TGL IJIN TPS">{`${model?.tgl_izin_tps}`} </Descriptions.Item>
                <Descriptions.Item label="NO MASTER BL AWB">{model?.mawb_number}</Descriptions.Item>
                <Descriptions.Item label="TGL MASTER BL AWB">{!!model?.mawb_date ? moment(model.mawb_date).format('YYYYMMDD') : null}</Descriptions.Item>
                <Descriptions.Item label="TGL DOK INOUT">{moment(model?.tgl_dok_inout).format('DD-MMM-YYYY')}</Descriptions.Item>
                <Descriptions.Item label="WK INOUT">{moment().format('YYYYMMDDHHmmss')}</Descriptions.Item>
                <Descriptions.Item label="ID CONSIGNEE">{model?.exportir_npwp}</Descriptions.Item>
                <Descriptions.Item label="CONSIGNEE">{model?.exportir_name}</Descriptions.Item>
                <Descriptions.Item label="KD SAR ANGKUT INOUT">4</Descriptions.Item>
                <Descriptions.Item label="NOPOL"></Descriptions.Item>
                <Descriptions.Item label="BRUTO">{model?.awb_id?.weight}</Descriptions.Item>
                <Descriptions.Item label="JML KEMAS">{model?.awb_id?.pieces}</Descriptions.Item>
                <Descriptions.Item label="KD KEMAS">GUDANG</Descriptions.Item>
                <Descriptions.Item label="Status">
                    {
                        model.state === "Verified" ?
                            <Tag color="gold">{model.state}</Tag> :
                            <>
                                {
                                    model.state === "Export Out" ?
                                        <Tag color="green">{model.state}</Tag> :
                                        <>
                                            {
                                                model.state === "Export In" ?
                                                    <Tag color="blue">{model.state}</Tag> :
                                                    <Tag color="red">{model.state}</Tag>
                                            }
                                        </>
                                }
                            </>
                    }
                </Descriptions.Item>
                <Descriptions.Item label="NO BC11">
                    <Input style={{ width: 200 }}
                        name="no_bc11"
                        value={model?.no_bc11}
                        onChange={handleChange}
                    />
                </Descriptions.Item>
                <Descriptions.Item label="TGL BC11">
                    <DatePicker
                        style={{ width: 200 }}
                        value={moment(model?.tgl_bc11, 'YYYYMMDD')}
                        onChange={handleDateChange} />
                </Descriptions.Item>
                <Descriptions.Item label="NO POS BC11">
                    <Input style={{ width: 200 }}
                        name="no_pos_bc11"
                        value={model?.no_pos_bc11}
                        onChange={handleChange}
                    />
                </Descriptions.Item>
            </Descriptions>

            <Space style={{ marginTop: 50 }}>
                <Button size='large' icon={<RollbackOutlined size={20} />} type="primary" disabled={model?.verified === true ? false : true} >
                    Manual
                </Button>
                <Button size='large' icon={<SwapOutlined />} type="primary" disabled={model?.verified === true ? false : true} onClick={() => handleSend("export_in")}>
                    Send Export In
                </Button>
                <Button size='large' icon={<SwapOutlined />} type="primary" disabled={!!model?.reff_number ? false : true} onClick={() => handleSend("export_out")}>
                    Send Export Out
                </Button>
                <Button size='large' icon={<SwapOutlined />} type="primary" disabled={!!model?.reff_number ? false : true} onClick={() => handleSend("export_cancel")}>
                    Send Export Cancel
                </Button>
                <Button size='large' icon={<CloseCircleOutlined />} type="primary" danger onClick={() => history.goBack()} >
                    Cancel
                </Button>
            </Space>
        </>
    )
}