import React from 'react'
import TablePage from '../../../../../../components/pages/TablePage'
import moment from 'moment'

export default () => { // eslint-disable-line
    const columns = [
        {
            selector: (row) => row?.reff_number,
            name: "Reff Number",
            id: 'reff_number'
        },
        {
            selector: (row) => row?.gudang_asal,
            name: "Gudang Asal",
            id: 'gudang_asal'
        },
        {
            selector: (row) => row?.gudang_tujuan,
            name: "Gudang Tujuan",
            id: 'gudang_tujuan'
        },
        {
            selector: (row) => row?.no_bl_awb,
            name: "BL AWB",
            id: 'no_bl_awb'
        },
        {
            selector: (row) => row?.no_voy_flight,
            name: "Flight",
            id: 'no_voy_flight'
        },
        {
            selector: (row) => row?.tgl_tiba,
            name: "TGL Tiba",
            id: 'tgl_tiba'
        },
        {
            selector: (row) => row?.nm_pemohon,
            name: "Nama Pemohon",
            id: 'nm_pemohon'
        },
        {
            selector: (row) => row?.jml_kemas,
            name: "JML kemas",
            id: 'jml_kemas'
        },
        {
            selector: (row) => moment(row?.createdAt).format("DD-MMM-YYYY"),
            name: "TGL Dibuat",
            id: 'createdAt'
        },
    ]
    return (
        <TablePage
            title={"Permohonan PLP"}
            url="api/v1/plp"
            actionPosition={'right'}
            createPath="/tps/plp/permohonan/form"
            columns={columns}
        />
    )
}