import React, { useState } from 'react';
import { Modal, Form, Input, Button, Row, Col, Divider, Collapse } from 'antd';
import { useFetch } from '../../../../../../hooks/useFetch';
import XMLViewer from 'react-xml-viewer';
import { toast } from 'react-toastify';
import {
    LoadingOutlined,
} from '@ant-design/icons';
import { parseXmlToFormData } from '../../../../../../hooks/parseKms';
import { PostAPI } from '../../../../../../redux';
import { useDispatch } from 'react-redux';
import VerifyResend from "../../../../../../components/pages/VerifyResend";

const { Panel } = Collapse;

export default ({ data }) => {
    const dispatch = useDispatch();

    const { awb_id, mawb_number, hawb_number, status, reff_number } = data;
    const search = { type: status === "Gate In" ? "NODOK Export In" : "NODOK Export Out", flight_no: awb_id?.flight_number, awb: mawb_number, hawb: hawb_number }
    const [rows, loading] = useFetch(`api/v1/tps_logs/detail-log?search=${JSON.stringify(search)}`);
    const items = rows || [];

    const [visible, setVisible] = useState(false);
    const [visibleVerif, setVisibleVerif] = useState(false);
    const [formData, setFormData] = useState({});

    const showModal = (item) => {
        setVisible(true);
        parseXmlToFormData(item?.request, setFormData);
    };

    const handleOk = () => {
        const xml = formData;
        const url = 'api/v1/tps_logs/resend_logs';
        dispatch(PostAPI({ url, data: { xml: JSON.stringify(xml), type: status.includes("In") ? "Export In" : "Export Out" } })).then(resp => {
            if (!!resp?.payload) {
                toast.success("Resend berhasil!");
                setVisible(false);
            }
        })
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleChange = (changedValues) => {
        setFormData({ ...formData, ...changedValues });
    };

    return (
        <>
            <VerifyResend visible={visibleVerif} setVisible={setVisibleVerif} handleProcess={handleOk} />
            <Modal
                title="Kirim Ulang"
                visible={visible}
                onOk={() => setVisibleVerif(true)}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => setVisibleVerif(true)}>
                        Send
                    </Button>,
                ]}
                width={"70%"}
            >
                <Form
                    layout="vertical"
                    initialValues={formData}
                    onValuesChange={(changedValues) => handleChange(changedValues)}
                >
                    {/* HEADER Fields */}
                    <Row gutter={16}>
                        <Divider plain>HEADER</Divider>
                        <Col span={6}>
                            <Form.Item label="Kode Dokumen" name="KD_DOK">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Kode TPS" name="KD_TPS">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Nama Angkut" name="NM_ANGKUT">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No Flight" name="NO_VOY_FLIGHT">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Call Sign" name="CALL_SIGN">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Tanggal Tiba" name="TGL_TIBA">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Kode Gudang" name="KD_GUDANG">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider plain>DETAIL</Divider>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="No BL AWB" name="NO_BL_AWB">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Tanggal BL AWB" name="TGL_BL_AWB">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No Master BL AWB" name="NO_MASTER_BL_AWB">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Tanggal Master BL AWB" name="TGL_MASTER_BL_AWB">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="ID Consignee" name="ID_CONSIGNEE">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Consignee" name="CONSIGNEE">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Bruto" name="BRUTO">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No Bc11" name="NO_BC11">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Tgl Bc11" name="TGL_BC11">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No Pos Bc11" name="NO_POS_BC11">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Const Asal" name="CONT_ASAL">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Seri Kemas" name="SERI_KEMAS">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Kode Kemas" name="KD_KEMAS">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Jumlah Kemas" name="JML_KEMAS">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Kode Timbun" name="KD_TIMBUN">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Kode Dok Inout" name="KD_DOK_INOUT">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="No Dok Inout" name="NO_DOK_INOUT">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Tanggal Dok Inout" name="TGL_DOK_INOUT">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Waktu Inout" name="WK_INOUT">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Kode Sar Angkut Inout" name="KD_SAR_ANGKUT_INOUT">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="No Pol" name="NO_POL">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Pel Muat" name="PEL_MUAT">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Pel Transit" name="PEL_TRANSIT">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Pel Bongkar" name="PEL_BONGKAR">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Pel Bongkar" name="PEL_BONGKAR">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Pel Tujuan" name="GUDANG_TUJUAN">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Pel Kantor" name="KODE_KANTOR">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No PEB" name="NO_DAFTAR_PABEAN">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Tgl PEB" name="TGL_DAFTAR_PABEAN">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No Segel Bc" name="NO_SEGEL_BC">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Tgl Segel Bc" name="TGL_SEGEL_BC">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="No Ijin Tps" name="NO_IJIN_TPS">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={16}>
                        <Col span={6}>
                            <Form.Item label="Tgl Ijin Tps" name="TGL_IJIN_TPS">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
            <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
                {loading ?
                    <div>
                        <LoadingOutlined style={{ fontSize: 20 }} />
                    </div> :
                    <div>
                        <Collapse style={{ width: "80%" }}>
                            {items?.map((item, i) => {


                                const reff = item?.request.split('<REF_NUMBER>')[1]?.split('</REF_NUMBER>')[0];
                                const lastreff = reff === reff_number ? `last send` : "";

                                return (
                                    <Panel header={`Logs ${i + 1} ${lastreff}`} key={i + 1} style={{ backgroundColor: reff === reff_number ? `#36BA98` : "" }}>
                                        <Button type="primary" onClick={() => showModal(item)} size='small'>
                                            Resend
                                        </Button>
                                        <p variant="h6">Request</p>
                                        <div style={{ display: 'flex', padding: 16, flexDirection: 'column', width: '60vw', overflow: 'scroll', border: 'solid 1px #efefef', marginBlock: 16 }}>
                                            {item?.request ?
                                                <XMLViewer xml={item?.request} /> :
                                                <p>No Request</p>
                                            }
                                        </div>
                                        <p variant="h6">Response</p>
                                        <div style={{ display: 'flex', padding: 16, flexDirection: 'column', width: '60vw', overflow: 'scroll', border: 'solid 1px #efefef', marginBlock: 16 }}>
                                            {item?.request ?
                                                <XMLViewer xml={item?.response} /> :
                                                <p>No Response</p>
                                            }
                                        </div>
                                    </Panel>
                                )
                            })}
                        </Collapse>
                    </div>
                }
            </div>
        </>
    )
}