import React from 'react';
import { Tag, Tooltip, message } from 'antd';
import TablePage from '../../../../../../components/pages/TablePage';

export default () => { // eslint-disable-line
    const columns = [
        {
            selector: (row) => <>
                {
                    row.status === "Gate In" ?
                        <Tag color="green">{row.status}</Tag> :
                        <>
                            {
                                row.status === "Gate Out" ?
                                    <Tag color="blue">{row.status}</Tag> :
                                    <Tag color="red">{row.status}</Tag>
                            }
                        </>
                }
            </>,
            name: "Gate",
            id: 'status'
        },
        {
            selector: (row) =>
                <Tooltip placement="top" title="Copy">
                    <span style={{ color: "red", cursor: "pointer" }} onClick={() => {
                        navigator.clipboard.writeText(row?.reff_number)
                        message.success('Berhasil di Copy');
                    }}>{row?.reff_number}</span>
                </Tooltip>,
            name: "Reff Number",
            width: "180px",
            id: 'reff_number',
            wrap: true,
        },
        {
            selector: (row) => row?.wk_inout || "-",
            name: "Waktu Inout",
            id: 'wk_inout',
            width: "200px"
        },
        {
            selector: (row) => row?.no_dok_inout,
            name: "No Doc",
            id: 'no_dok_inout',
            width: "200px"
        },
        {
            selector: (row) => row?.mawb_number,
            name: "MAWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => <span>{row?.muat} - {row?.bongkar}</span>,
            name: "Muat Bongkar",
            id: 'state'
        },
        {
            selector: (row) => row?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.flight_date,
            name: "FL Date",
            id: 'flight_date_str'
        },
        {
            selector: (row) => row?.exportir_npwp,
            name: "Exp NPWP",
            id: 'exportir_npwp'
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Exp Name",
            id: 'exportir_name',
        },
    ]
    return (
        <TablePage
            title={"Distribusi List"}
            url="api/v1/tps_distribusi/list"
            actionPosition={'right'}
            columns={columns}
        />
    )
}