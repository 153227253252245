import React from 'react';
import { Table } from 'antd';
import reduceSum from 'reduce-sum';

export default ({ data }) => {
    const columns = [
        {
            title: 'Awb Number',
            dataIndex: 'awb_id',
            key: 'awb_id',
            render: (awb_id) => {
                return (
                    <span>{awb_id?.awb_number}</span>
                )
            },
        },
        {
            title: 'Origin',
            dataIndex: 'awb_id',
            key: 'awb_id',
            render: (awb_id) => {
                return (
                    <span>{awb_id?.origin?.code}</span>
                )
            },
        },
        {
            title: 'Dest',
            dataIndex: 'awb_id',
            key: 'awb_id',
            render: (awb_id) => {
                return (
                    <span>{awb_id?.dest?.code}</span>
                )
            },
        },
        {
            title: 'ULD',
            dataIndex: 'uld',
            key: 'uld',
            render: (uld) => {
                return (
                    <span>{uld}</span>
                )
            },
        },
        {
            title: 'Product Desc',
            dataIndex: 'packet_desc',
            key: 'packet_desc',
            render: (packet_desc) => {
                return (
                    <span>{packet_desc}</span>
                )
            },
        },
        {
            title: 'Koli',
            dataIndex: 'pieces',
            key: 'pieces'
        },
        {
            title: 'Split',
            dataIndex: 'split',
            key: 'split'
        },
        {
            title: 'Partial',
            dataIndex: 'partial',
            key: 'partial'
        },
        {
            title: 'Kilo',
            dataIndex: 'weight',
            key: 'weight'
        },
        {
            title: 'Volume',
            dataIndex: 'volume',
            key: 'volume'
        },
        {
            title: 'Agent',
            dataIndex: 'awb_id',
            key: 'awb_id',
            render: (awb_id) => {
                return (
                    <span>{awb_id?.agent?.name}</span>
                )
            },
        },
        {
            title: 'Shipper',
            dataIndex: 'awb_id',
            key: 'awb_id',
            render: (awb_id) => {
                return (
                    <span>{awb_id?.shipper?.name}</span>
                )
            },
        },
        {
            title: 'Consigne',
            dataIndex: 'awb_id',
            key: 'awb_id',
            render: (awb_id) => {
                return (
                    <span>{awb_id?.consigne?.name}</span>
                )
            },
        },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
            <Table columns={columns}
                rowKey={(record) => record?._id}
                expandable={{
                    expandedRowRender: (record) => (
                        <table style={{ border: '1px solid black' }}>
                            <tr style={{ border: '1px solid black' }}>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>House Number</th>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>Pieces</th>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>Weight</th>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>Shipper</th>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>Consignee</th>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>Commodity</th>
                            </tr>
                            {record?.hawbs?.map((item, index) => (
                                <>
                                    <tr style={{ border: '1px solid black' }}>
                                        <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.house_number}</td>
                                        <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.pieces}</td>
                                        <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.weight}</td>
                                        <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.shipper || "-"}</td>
                                        <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.consignee || "-"}</td>
                                        <td style={{ border: '1px solid black', fontSize: 10, textAlign: "center" }}>{item?.commodity}</td>
                                    </tr>
                                </>
                            ))}
                            <tr style={{ border: '1px solid black' }}>
                                <th colSpan={4} style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>Total</th>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>{reduceSum(record?.hawbs, 'pieces')}</th>
                                <th style={{ border: '1px solid black', fontSize: 11, textAlign: "center" }}>{reduceSum(record?.hawbs, 'weight')}</th>
                            </tr>
                        </table>
                    ),
                    rowExpandable: (record) => record._id !== 'Not Expandable',
                }}
                dataSource={data?.manifest} />

        </div>
    )
}