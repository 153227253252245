import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading, unsetLoading } from './apps';
import { GetData, PostData } from '../caller';
import { logout } from './auth';
import Swal from 'sweetalert2';

const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

const doTokenInvalid = dispatch => () => {
    dispatch(logout())
}
export const PostAPI = createAsyncThunk('API/Post', async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi
    dispatch(setLoading())
    const { auth: { apps, token } } = getState()
    const { url, data } = payload
    let response = false
    try {
        const resp = await PostData(url, data, token, apps, doTokenInvalid(dispatch))
        response = resp.data
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: error.message
        })
    }
    dispatch(unsetLoading())
    return response
})

export const GetAPI = createAsyncThunk('API/Get', async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi
    dispatch(setLoading())
    const { auth: { apps, token } } = getState()
    const { url } = payload
    let response = false
    try {
        response = await GetData(url, token, apps, doTokenInvalid(dispatch))
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: error.message
        })
    }
    dispatch(unsetLoading())
    return response
})


export const GetME = createAsyncThunk('API/Get', async (payload, thunkApi) => {
    const { dispatch, getState } = thunkApi
    dispatch(setLoading())
    const { auth: { apps, token } } = getState()
    const { url } = payload
    let response = false
    try {
        response = await GetData(url, token, apps, doTokenInvalid(dispatch))
    } catch (error) {
        Toast.fire({
            icon: 'error',
            title: error.message
        })
    }
    dispatch(unsetLoading())
    return response
})