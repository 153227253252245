import React, { Fragment, useState } from 'react';
import moment from 'moment';
import { Form, Input, Row, Col, DatePicker, InputNumber, Button, Space, Tabs, Tooltip } from 'antd';
import FormPage from '../../../../../../components/pages/FormPage';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RiFlightLandFill } from 'react-icons/ri';

const DefModel = {
    flight_number: null,
    flight_date: moment(),
    no_bc11: "",
    tgl_bc11: moment(),
    awbs: []
}

export default () => {
    const history = useHistory();
    const [model, setModel] = useState(DefModel)

    const formatAwbNumber = (value) => {
        let str = value;

        if (/^\d/.test(str)) {
            if (str.length > 3 && str.substring(3, 4) !== '-') {
                str = str.substring(0, 3) + '-' + str.substring(3);
            }
        }
        return str;
    }

    return (
        <Fragment>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Form" key="1">
                    <FormPage
                        url={'api/v1/import_awb/add'}
                        formValid={false}
                        model={model}
                        layout="vertical"
                        setModel={setModel}
                        formSize="default"
                        title={"Master AWB Import"}
                        emptyModel={DefModel}
                        callbackPath={"/tps/import/awb"}
                        onSaveEvent={(model) => {
                            const { flight_number, flight_date, no_bc11, tgl_bc11, awbs } = model;
                            const r = awbs.map(
                                ({
                                    mawb_number,
                                    hawb_date,
                                    mawb_date,
                                    bruto,
                                    jml_kemas,
                                }) => {
                                    return {
                                        mawb_number,
                                        hawb_number: mawb_number,
                                        mawb_date: moment(mawb_date).format('YYYYMMDD'),
                                        hawb_date: moment(hawb_date).format('YYYYMMDD'),
                                        bruto,
                                        jml_kemas,
                                    }
                                })
                            return {
                                flight_number,
                                flight_date: moment(flight_date).format('DD-MMM-YYYY'),
                                no_bc11,
                                tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'),
                                awbs: r,
                            }
                        }}
                    >
                        <Row gutter={24} style={{ width: '50%' }}>
                            <Col span={11}>
                                <Form.Item
                                    label="Flight Number"
                                    name="flight_number"
                                    rules={[{ required: true, message: "Flight Number wajib di isi" }]}
                                >
                                    <Input
                                        autoFocus
                                        autoComplete="off"
                                        onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                        addonAfter={<Tooltip placement="topLeft" title="Create Flight">
                                            <RiFlightLandFill style={{ cursor: "pointer" }} onClick={() => history.push({ pathname: '/fr/create' })} />
                                        </Tooltip>}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="flight date" name={'flight_date'} rules={[{ required: true, message: "Flight Date wajib di isi" }]}>
                                    <DatePicker keyboard style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                                </Form.Item>
                            </Col>
                            <Col span={11}>
                                <Form.Item label="BC 11" name={'no_bc11'}>
                                    <Input maxLength={6} autoComplete="off" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="TGL BC 11" name={'tgl_bc11'}>
                                    <DatePicker style={{ width: '100%' }} format="DD-MMM-YYYY" placeholder='' />
                                </Form.Item>
                            </Col>

                            <Form.List
                                name="awbs"
                                initialValue={model?.awbs}
                            >
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey, ...restField }) => (
                                            <Row gutter={24} style={{ marginLeft: 1, border: "1px solid #ddd", paddingTop: 20, marginTop: 10 }}>
                                                <Col span={11}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'mawb_number']}
                                                        fieldKey={[fieldKey, 'mawb_number']}
                                                        label="mawb number"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                pattern: /^[A-Za-z0-9]+-[A-Za-z0-9]+$/,
                                                                message: 'Nomor mawb harus dalam format 000-0000000',
                                                            },
                                                        ]}
                                                        getValueFromEvent={(e) => formatAwbNumber(e.target.value = e.target.value.toUpperCase())}
                                                    >
                                                        <Input autoComplete="off" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'mawb_date']}
                                                        fieldKey={[fieldKey, 'mawb_date']}
                                                        label="mawb date"
                                                        allowClear={false}
                                                    >
                                                        <DatePicker format="DD-MMM-YYYY" defaultValue={moment()} style={{ width: '100%' }} placeholder='' />
                                                    </Form.Item>
                                                </Col>
                                                <MinusCircleOutlined onClick={() => { remove(name); }} />

                                                <Col span={11}>
                                                    <Form.Item label="hawb number"
                                                        {...restField}
                                                        name={[name, 'mawb_number']}
                                                        fieldKey={[fieldKey, 'mawb_number']}
                                                        rules={[{ required: true, message: "MAWB Number wajib di isi" }]}
                                                    >
                                                        <Input disabled autoComplete="off" />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="hawb date"
                                                        {...restField}
                                                        name={[name, 'hawb_date']}
                                                        fieldKey={[fieldKey, 'hawb_date']}
                                                        allowClear={false}
                                                    // rules={[{ required: true, message: "HAWB Date wajib di isi" }]}
                                                    >
                                                        <DatePicker format="DD-MMM-YYYY" defaultValue={moment()} style={{ width: '100%' }} placeholder='' />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={11}>
                                                    <Form.Item label="pieces"
                                                        {...restField}
                                                        name={[name, 'jml_kemas']}
                                                        fieldKey={[fieldKey, 'jml_kemas']}
                                                        rules={
                                                            [
                                                                { required: true, message: "Pieces wajib di isi" },
                                                                {
                                                                    validator: (_, value) =>
                                                                        value === 0 ? Promise.reject(new Error('Pieces tidak bisa 0')) : Promise.resolve(),
                                                                },
                                                            ]
                                                        }>
                                                        <InputNumber style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item label="weight"
                                                        {...restField}
                                                        name={[name, 'bruto']}
                                                        fieldKey={[fieldKey, 'bruto']}
                                                        rules={

                                                            [
                                                                { required: true, message: "Weight wajib di isi" },
                                                                {
                                                                    validator: (_, value) =>
                                                                        value === 0 ? Promise.reject(new Error('Weight tidak bisa 0')) : Promise.resolve(),
                                                                },
                                                            ]
                                                        }>
                                                        <InputNumber style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Space style={{ marginTop: 10, marginLeft: 12 }}>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        add();
                                                    }}
                                                    icon={<PlusOutlined />}
                                                >
                                                    Add AWB
                                                </Button>
                                            </Form.Item>
                                        </Space>
                                    </>
                                )}
                            </Form.List>
                        </Row>
                    </FormPage>
                </Tabs.TabPane>
            </Tabs>
        </Fragment >
    )
}