import { useEffect, useState } from 'react';
import moment from 'moment';
import Config from '../config/env'
const { WsUrl } = Config

export const useAutogate = (name) => {
    const [reconnect, setReconnect] = useState(0);
    const [atgData, setAtgData] = useState({})

    useEffect(() => {
        const ws = new WebSocket(`${WsUrl}?name=${name}`);

        ws.onmessage = evt => {
            const message = JSON.parse(evt.data);
            if (message?.awb) {
                setTimeout(() => {
                    setAtgData(message);
                }, 0);
            }
        };

        ws.onclose = () => {
            setTimeout(() => {
                setReconnect(moment().unix());
            }, 1000)
        }

        return () => {
            if (ws) {
                ws.close(1000);
            }
        }
    }, [reconnect]);

    return { atgData };
}