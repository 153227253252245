import React from 'react'
import TablePage from '../../../../../../components/pages/TablePage'

export default () => {
    const columns = [
        {
            selector: (row) => row?.mawb_number,
            name: "Master AWB Number",
            id: 'mawb_number'
        },
        {
            selector: (row) => row?.hawb_number,
            name: "HAWB Number",
            id: 'hawb_number'
        },
        {
            selector: (row) => row?.exportir_npwp,
            name: "NPWP",
            id: 'exportir_npwp'
        },
        {
            selector: (row) => <span>{row?.awb_id?.origin?.code} - {row?.awb_id?.dest?.code}</span>,
            name: "Route",
            id: 'state'
        },
        {
            selector: (row) => row?.awb_id?.flight_number,
            name: "FL Number",
            id: 'flight_number'
        },
        {
            selector: (row) => row?.awb_id?.flight_date,
            name: "FL Date",
            id: 'flight_date'
        },
        {
            selector: (row) => row?.exportir_name,
            name: "Exportir Name",
            id: 'exportir_name',
            wrap: true,
        },
        {
            selector: (row) => row?.no_dok_inout,
            name: "No Document",
            id: 'no_dok_inout',
            wrap: true,
        },
        {
            selector: (row) => row?.tgl_dok_inout,
            name: "Document Date",
            id: 'tgl_dok_inout'
        },
    ]
    return (
        <TablePage
            title={"Distribusi Cancel"}
            url="api/v1/tps/export_cancel"
            actionPosition={'right'}
            editPath={"/tps/distribusi/distribusi_cancel/form"}
            columns={columns}
        />
    )
}