import React, { useEffect, useState } from 'react'
import { Modal, Form, Input, Select, Button, Row, Col, DatePicker, message, Tabs } from 'antd';
import { PostAPI } from '../../../../../redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import TablePage from '../../../../../components/pages/TablePage';
import {
    RiArrowLeftCircleFill,
} from 'react-icons/ri';
import { useFetch } from '../../../../../hooks/useFetch';
const { Option } = Select;

export default ({ isModalOpen, handleCancel, awb, setRefresh }) => { // eslint-disable-line

    const dispatch = useDispatch()
    const [form] = Form.useForm();
    const [rows, loading] = useFetch(`api/v1/verify/check_document_bc/${awb?.hawb_number}`);

    const [dataDocument, setDataDocument] = useState(null)
    const [refresh, setRefreshTable] = useState(moment().unix());
    const [docType, setDocType] = useState(null) // eslint-disable-line
    const [idDoc, setIdDoc] = useState(null)
    const [docAuto, setDocAuto] = useState(false)

    useEffect(() => {
        if (rows) {
            setDocAuto(true)
            const { tgl_sppb, _id, nm_angkut, no_master_bl_awb, no_bl_awb, tgl_bl_awb, tgl_master_bl_awb, kode_doc, name_doc, tgl_bc11 } = rows;
            setIdDoc(_id)
            setDocType(name_doc)
            setDataDocument({
                ...rows,
                tgl_sppb: moment(tgl_sppb).format('YYYYMMDD'),
                nama_angkut: nm_angkut, awb_number: no_master_bl_awb, hawb_number: no_bl_awb,
                tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'),
                awb_date: tgl_master_bl_awb === "" ? "" : moment(tgl_master_bl_awb).format('YYYYMMDD'), hawb_date: tgl_bl_awb === "" ? "" : moment(tgl_bl_awb).format('YYYYMMDD'), doc_no: kode_doc
            })
        }
    }, [rows])

    const closeModal = () => {
        setDataDocument(null)
        setDocType(null)
        setIdDoc(null)
        handleCancel()
        form.resetFields();
        setDocAuto(false)
    }

    const findDocument = (value) => {
        dispatch(PostAPI({ url: 'api/v1/verify/import_verify', data: { ...value, doc_date: moment(value?.doc_date).format("DDMMYYYY") } }))
            .then(resp => {
                if (resp?.payload?.gudang) {
                    setDataDocument({ ...resp.payload, doc_type: value?.current_kd_dok })
                } else {
                    setDataDocument(null)
                    message.error('Tidak Menemukan Data');
                }
            })
    }

    const saveDocument = () => {
        const { no_sppb, tgl_sppb, jns_kms,
            hawb_number, hawb_date, no_pos_bc11,
            npwp_imp, doc_no, no_pib, tgl_pib, no_bc11 } = dataDocument;
        dispatch(PostAPI({
            url: 'api/v1/verify/import_verify_save', data: {
                _id: awb?._id, id_doc: idDoc, doc_type: docType,
                no_sppb, tgl_sppb, jns_kms,
                npwp_imp, doc_no,
                no_pib, tgl_pib, no_bc11,
                hawb_number, hawb_date, no_pos_bc11
            }
        }))
            .then(resp => {
                if (resp.payload) {
                    setRefresh(moment().unix())
                    handleCancel()
                    closeModal()
                    message.success('berhasil verifikasi');
                }
            })
    }

    const saveVerify = () => {
        form.validateFields()
            .then((values) => {
                const { current_kd_dok, doc_date, no_dok_inout, exportir_npwp, doc_no } = values;
                dispatch(PostAPI({
                    url: 'api/v1/verify/doc_save', data: {
                        doc_type: current_kd_dok || '',
                        doc_date: doc_date || '',
                        doc_code: doc_no || '',
                        no_doc: no_dok_inout || '',
                        no_npwp: exportir_npwp || '',
                        exp_imp: 'import',
                    }
                }))
                    .then(resp => {
                        if (resp.payload) {
                            setRefreshTable(moment().unix());
                            form.resetFields();
                            message.success('berhasil save');
                        }
                    })
            })
    }

    const handleChange = (value) => {
        setDocType(value)
    };

    const columns = [
        {
            name: "Action",
            id: '_id',
            width: '100px',
            selector: (row) => {
                const { _id, doc_type, no_doc, no_npwp, doc_date, doc_code } = row;
                return (
                    <RiArrowLeftCircleFill size={20} style={{ color: 'green', cursor: 'pointer' }}
                        onClick={() => {
                            if (form) {
                                form.setFieldsValue({
                                    current_kd_dok: doc_type,
                                    no_dok_inout: no_doc,
                                    doc_no: doc_code,
                                    exportir_npwp: no_npwp,
                                    doc_date: moment(doc_date, 'YYYY-MM-DD')
                                });
                                setIdDoc(_id)
                                handleChange(doc_type)
                            }
                        }}
                    />
                )
            }
        },
        {
            selector: (row) => row?.no_doc,
            name: "No Doc",
            id: 'no_doc',
            width: '200px'
        },
        {
            selector: (row) => row?.doc_type,
            name: "Doc Type",
            id: 'doc_type'
        }
    ]

    const ListVerifyDoc = () => {
        const {
            doc_no,
            gudang,
            car,
            no_sppb,
            tgl_sppb,
            no_pib,
            tgl_pib,
            nama_angkut,
            no_voy_flight,
            no_bc11,
            tgl_bc11,
            no_pos_bc11,
            awb_number,
            awb_date,
            hawb_number,
            hawb_date,
            nama_imp,
            alamat_imp,
            npwp_imp,
            nama_ppjk,
            kd_kantor_pengawas,
            kd_kantor_bongkar,
            bruto,
            netto,
            jml_kms,
            jns_kms
        } = dataDocument;
        return (
            <>
                <table style={{ marginLeft: 30 }}>
                    <tr>
                        <td style={{ width: 200 }}>DOC NO</td>
                        <td>: {doc_no || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Nama Gudang</td>
                        <td>: {gudang || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Nomor CAR / Aju</td>
                        <td>: {car || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No SPPB / Tgl SPPB</td>
                        <td>: {no_sppb || "-"} / {tgl_sppb ? moment(tgl_sppb).format('DD-MM-YYYY') : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No PIB / Tgl PIB</td>
                        <td>: {no_pib || "-"} / {tgl_pib ? moment(tgl_pib).format('DD-MM-YYYY') : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Nama Angkut</td>
                        <td>: {nama_angkut || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No Flight</td>
                        <td>: {no_voy_flight || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No BC11 / Tgl BC11</td>
                        <td>: {no_bc11 || "-"} / {tgl_bc11 ? moment(tgl_bc11).format('DD-MM-YYYY') : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No Pos BC11</td>
                        <td>: {no_pos_bc11 || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No HAWB / Tgl HAWB</td>
                        <td>: {hawb_number || "-"} / {hawb_date ? moment(hawb_date).format('DD-MM-YYYY') : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>No AWB / Tgl AWB</td>
                        <td>: {awb_number || "-"} / {awb_date ? moment(awb_date).format('DD-MM-YYYY') : "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>NPWP IMP</td>
                        <td>: {npwp_imp || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Name IMP</td>
                        <td>: {nama_imp || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Alamat IMP</td>
                        <td>: {alamat_imp || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Name PPJK</td>
                        <td>: {nama_ppjk || "-"}</td>
                    </tr>

                    <tr>
                        <td style={{ width: 200 }}>Kantor Pengawas</td>
                        <td>: {kd_kantor_pengawas || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Kantor Bongkar</td>
                        <td>: {kd_kantor_bongkar || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Pieces</td>
                        <td>: {jml_kms || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Packing</td>
                        <td>: {jns_kms || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Weight</td>
                        <td>: {bruto || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Netto</td>
                        <td>: {netto || "-"}</td>
                    </tr>
                    <tr>
                        <td style={{ width: 200 }}>Status Jalur</td>
                        <td>: Hijau</td>
                    </tr>
                </table>
                <Button type="primary" style={{ marginLeft: 32, width: '40%', marginTop: 10 }} onClick={() => saveDocument()}>
                    Save
                </Button>
            </>
        )
    };

    return (
        <div>
            <Modal title={`Verify AWB ${awb?.mawb_number}`} visible={isModalOpen} onCancel={closeModal} footer={null} width={1200}>
                {docAuto ?
                    <ListVerifyDoc /> :
                    <Row justify="space-around">
                        <Col span={9}>
                            <Form
                                layout='vertical'
                                onFinish={findDocument}
                                form={form}
                                size='large'
                            >
                                <Form.Item
                                    name="current_kd_dok"
                                    label={<span style={{ fontSize: "16px" }}>Type Document</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Type Document wajib di isi"
                                        },
                                    ]}
                                >
                                    <Select
                                        allowClear
                                        onChange={handleChange}
                                    >
                                        <Option value="SPPB2.0">SPPB BC 2.0</Option>
                                        <Option value="SPPB2.3">SPPB BC 2.3</Option>
                                        <Option value="PLP">PLP</Option>
                                        <Option value="dokumen_pabean_permit">Dokumen Pabean Permit</Option>
                                    </Select>
                                </Form.Item>

                                {(docType === "dokumen_pabean_permit") &&
                                    <Form.Item
                                        name="doc_no"
                                        label={<span style={{ fontSize: "16px" }}>Kode Document</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Kode Document wajib di isi"
                                            },
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            allowClear
                                        >
                                            <Option value="4">4, SPPB BC 1.2</Option>
                                            <Option value="9">9, BCF 1.5 / Barang Tidak Dikuasai</Option>
                                            <Option value="13">13, SPPB PIBK</Option>
                                            <Option value="28">28, Dokumen BC 1.2</Option>
                                            <Option value="30">30, Pengeluaran Barang Penegahan (Sebagian)</Option>
                                            <Option value="31">31, Pengeluaran Barang Penegahan (Seluruhnya)</Option>
                                            <Option value="41">41, BC 1.6</Option>
                                            <Option value="35">35, ATA CARNET Impor</Option>
                                            <Option value="36">36, CPD CARNET Impor</Option>
                                            <Option value="42">42, Persetujuan Keluar Barang Kiriman</Option>
                                            <Option value="43">43, SPPBMC</Option>
                                            <Option value="44">44, Impor dengan BC 1.1 Outward Manifes</Option>
                                            <Option value="45">45, NPP3BET(Nota Persetujuan Pengeluaran P3BET)</Option>
                                            <Option value="47">47, PPFTZ-01 Asal LDP (Impor)</Option>
                                            <Option value="48">48, PPFTZ-01 Tujuan TLDDP</Option>
                                            <Option value="49">49, PPFTZ-01 Tujuan LDP (Ekspor)</Option>
                                            <Option value="50">50, PPFTZ-02 Masuk</Option>
                                            <Option value="51">51, PPFTZ-02 Keluar</Option>
                                            <Option value="52">52, PPFTZ-03</Option>
                                            <Option value="56">56, Ekspor dengan BC 1.1 Inward Manifes</Option>
                                            <Option value="59">59, Dokumen BC 1.4</Option>
                                            <Option value="60">60, SPPB Rush Handling</Option>
                                            <Option value="61">61, KEK- Pemasukan dari LDP</Option>
                                            <Option value="62">62, KEK- Pemasukan melalui Barang Kiriman</Option>
                                            <Option value="63">63, KEK- Pemasukan dari TLDDP</Option>
                                            <Option value="64">64, KEK- Pengeluaran ke KEK/TPB/FTZ</Option>
                                            <Option value="65">65, KEK- Pengeluaran ke LDP</Option>
                                            <Option value="66">66, KEK- Pengeluaran ke TLDDP</Option>
                                            <Option value="99">99, Dokumen Pengeluaran Lainnya....</Option>
                                        </Select>
                                    </Form.Item>
                                }

                                <Form.Item
                                    name="no_dok_inout"
                                    label={<span style={{ fontSize: "16px" }}>No Document</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: "No Document wajib di isi"
                                        },
                                    ]}
                                >
                                    <Input autoComplete="off" onInput={e => e.target.value = e.target.value.toUpperCase()} />
                                </Form.Item>
                                <Form.Item
                                    name="doc_date"
                                    label={<span style={{ fontSize: "16px" }}>Tanggal Doc</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Tanggal Doc wajib di isi"
                                        },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="" />
                                </Form.Item>

                                {(docType === "SPPB2.0" || docType === "SPPB2.3") &&
                                    <Form.Item
                                        name="exportir_npwp"
                                        label={<span style={{ fontSize: "16px" }}>NPWP</span>}
                                        rules={[
                                            {
                                                required: true,
                                                message: "NPWP wajib di isi"
                                            },
                                        ]}
                                    >
                                        <Input autoComplete="off" />
                                    </Form.Item>
                                }
                                <Form.Item style={{ marginTop: 60 }}>
                                    <Button type="primary" htmlType="submit">
                                        Check Verify Document
                                    </Button>
                                    <Button type="dashed" style={{ marginLeft: 10 }} onClick={() => {
                                        saveVerify()
                                    }}>
                                        Save
                                    </Button>
                                    <Button type="dashed" style={{ marginLeft: 10 }} onClick={() => {
                                        setDataDocument(null)
                                        form.resetFields()
                                    }}>
                                        Clear
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Col>
                        {dataDocument === null ?
                            <Col span={14}>
                                <TablePage
                                    url="api/v1/verify/verify_doc/import"
                                    columns={columns}
                                    refresh={refresh}
                                    title={"Import Verify"}
                                />
                            </Col> :
                            <Col span={14} >
                                <ListVerifyDoc />
                            </Col>
                        }
                    </Row>
                }
            </Modal>
        </div>
    )
}