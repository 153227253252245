import React from 'react';
import { Button, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import { FiCopy, FiEdit } from "react-icons/fi";
import moment from 'moment';
import DetailHawb from './detailHawb';


export default ({ data }) => {
    const history = useHistory();

    const columns = [
        {
            title: 'No',
            dataIndex: 'no',
            key: 'no',
            render: (text, record, index) => index + 1,
        },
        {
            render: (value) => {
                return (
                    (value && moment(value).format('DD-MMM-YYYY')) || '-'
                )
            },
            title: "Create Date",
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Awb Number',
            dataIndex: 'mawb_number',
            key: 'mawb_number',
        },

        {
            title: 'Flight Number',
            dataIndex: 'flight_number',
            key: 'flight_number',
        },
        {
            title: 'Flight Date',
            dataIndex: 'flight_date',
            key: 'flight_date',
        },

        {
            title: 'No BC11',
            dataIndex: 'no_bc11',
            key: 'no_bc11'
        },
        {
            title: 'Tgl BC11',
            dataIndex: 'tgl_bc11',
            key: 'tgl_bc11',
            render: (value) => {
                return (
                    (value && moment(value).format('DD-MMM-YYYY')) || '-'
                )
            },
        },

        {
            title: 'Pieces',
            dataIndex: 'jml_kemas',
            key: 'jml_kemas',
        },
        {
            title: 'Weight',
            dataIndex: 'bruto',
            key: 'bruto',
        },
        {
            render: (value) => {
                return (
                    value?.code
                )
            },
            title: "Origin",
            dataIndex: 'origin',
            key: 'origin'
        },
        {
            render: (value) => {
                return (
                    value?.code
                )
            },
            title: "Dest",
            dataIndex: 'dest',
            key: 'dest'
        },
        {
            title: "Action",
            render: (row) => {
                return (
                    <>
                        <Button
                            icon={<FiCopy size={18} color="black" />}
                            style={{ paddingInline: 8 }}
                            shape={'round'}
                            type="link"
                            onClick={() => history.push({ pathname: '/tps/import/awb_hawbform', state: { ...row, isCreate: false } })}
                        />
                        <Button
                            icon={<FiEdit size={18} color="black" />}
                            style={{ paddingInline: 8 }}
                            shape={'round'}
                            type="link"
                            onClick={() => history.push({ pathname: '/tps/import/awb_editform', state: { ...row, isCreate: false } })}
                        />
                    </>
                )
            },
        },
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column', padding: 32 }}>
            <Table
                rowKey="_id"
                columns={columns}
                expandable={{
                    expandedRowRender: (record) => {
                        return (
                            <DetailHawb record={record} />
                        )
                    },
                    rowExpandable: (record) => record._id !== 'Not Expandable',
                }}
                dataSource={data?.awbs}
                size='small'
            />
        </div>
    )
}