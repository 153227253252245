import React from 'react'
import FormReport from "../../../../../../components/pages/inventory/ReportInventory";
import { useFetch } from '../../../../../../hooks/useFetch';

export default () => {

    return (
        <FormReport
            title={'Distribusi Custome Module'}
            repType={'custome_module'}
            url={"/api/v1/report_distribusi"}
            FetchComponent={useFetch}
        />
    )
}