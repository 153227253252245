import React, { Fragment, useState } from 'react';
import { Form, Input, Row, Col, DatePicker } from 'antd';
import FormPage from '../../../../../../components/pages/FormPage';
import moment from 'moment';

const DefModel = {
    no_bc11: 0,
    tgl_bc11: moment()
}

export default () => {
    const [model, setModel] = useState(DefModel);
    const resultModel = { ...model }
    return (
        <Fragment>
            <FormPage
                url={'api/v1/import_awb/edit_by_flight'}
                formValid={false}
                model={{
                    ...model,
                    tgl_bc11: !model?.tgl_bc11 ? moment() : moment(model?.tgl_bc11)
                }}
                layout="vertical"
                setModel={setModel}
                formSize="default"
                title={"BC11 Awb by all Flight"}
                emptyModel={DefModel}
                callbackPath={"/tps/import/awb"}
                onSaveEvent={(model) => {
                    const { tgl_bc11 } = model;
                    return { ...model, tgl_bc11: moment(tgl_bc11).format('YYYYMMDD'), };
                }}
            >
                <Row gutter={24} style={{ width: '50%' }}>
                    <Col span={12}>
                        <Form.Item label="No BC11" name={'no_bc11'}
                        >
                            <Input autoComplete="off" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={"TGL BC11"}
                            name={"tgl_bc11"}
                            rules={[
                                {
                                    required: true,
                                    message: "TGL BC11 wajib di isi"
                                },
                            ]}
                        >
                            <DatePicker
                                style={{ width: '100%' }}
                                format="DD-MMM-YYYY"
                                allowClear={false}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </FormPage>
        </Fragment >
    )
}