import React from 'react'
import FormReport from "../../../../../../components/pages/inventory/ReportInventory";
import { useFetch } from '../../../../../../hooks/useFetch';

export default () => {

    return (
        <FormReport
            title={'Export Current Now'}
            repType={'current_now'}
            url={"/api/v1/report_export"}
            FetchComponent={useFetch}
        />
    )
}